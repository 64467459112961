
import store from "../store";
// import { useForm,useField } from 'vee-validate'
import { Field, Form, ErrorMessage, useField } from "vee-validate";
import * as Yup from "yup";
import { LOGIN } from "../store/modules/actions.type";
import { computed, ref, onBeforeMount } from "vue";
import { routeLocationKey, useRoute } from "vue-router";
import Logo from "@/components/Logo.vue";
import domainMixin from "../mixins/domain.js"

export default {
  mixins : [domainMixin],
  components: {
    Logo,
    Field,
    Form,
    ErrorMessage,
  },
  methods : {
    handleSubmit(values) {
      store.state.auth.state.error = [];
      store.dispatch(LOGIN, values);
    }
  },
  setup() {
    const route = useRoute();

    const schema = Yup.object().shape({
      email: Yup.string().email('E-Mail muss eine gültige E-Mail sein.').required("E-Mailadresse wird benötigt").label("E-mail"),
      password: Yup.string().min(8, 'Passwort muss mindestens 8 Zeichen lang sein.').required("Passwort wird benötigt").label("Password"),
    });

    const backendError = computed(() => store.state.auth.state.error);
    console.log(backendError);
    const showPassword = ref(false);

    function toggleShow() {
      showPassword.value = !showPassword.value;
    }

    return {
      backendError,
      schema,
      showPassword,
      toggleShow
    };
  },
};
