import {createApp} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import axios from "axios";
import Notifications from "@kyvg/vue3-notification";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import "./css/index.scss";
import i18n from "./i18n";
import 'tw-elements';
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
declare const document : any;

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/", "/password/reset", "/sales-login", "/sales-register", "/consultant/register"];

  const reset = to.path.match(/^[/](reset|register|sales-register)[/]?[?a-zA-z0-9]*/);
  const authRequired = !publicPages.includes(to.path);
  const verificationPages = ["/verify", "/email2fa","/google2fa", "/introduction"];
  const verificationRequired = !verificationPages.includes(to.path);
  const loggedIn = localStorage.getItem("ID_TOKEN_KEY");
  const email_verified = localStorage.getItem("EMAIL_VERIFIED_AT");
  const email2fa = localStorage.getItem("EMAIL2FA");
  const google2fa = localStorage.getItem("GOOGLE2FA");  
  let role = localStorage.getItem("ROLE");
  const user = localStorage.getItem("USER") ?? '';
  if(!role && user){
    role =  JSON.parse(user).role[0];
  }

  // if(!role){
  //   role = JSON.parse(localStorage.getItem("USER"));
  // }
  const company = localStorage.getItem("COMPANY");
  const sales = localStorage.getItem("SALES_ID_TOKEN_KEY");

  /**
   * For now as we are not having any meta title so this will change title to cyberxperts/securious
   * In the future, we will have to put condition so that change title per next link
   */
  changeTitleForPage(to);

  if (to.path == '/sales-login'){
    if (!sales) {
      next();
    }
    else {
      next('/sales-invite')
    }
  }

  if (to.path == '/sales-invite'){
    if (sales){
      axios.defaults.headers.common["Authorization"] = "Bearer " + sales;
      next();
    }
    else {
      next('/sales-login')
    }
  }

  if (reset) {
    next();
  }
  if (authRequired && !loggedIn) {
    next("/login");
  }
  else if (!email_verified && loggedIn) {
    if (!verificationRequired) {
      next();
    }
    else if (!authRequired) {
      next();
    }
    else next("/verify");
  } else if ((company == "0" && role !== "Admin") && loggedIn && email_verified) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + loggedIn;
    if (!verificationRequired) {
      next();
    }
    else next("/introduction");
  } else if (email2fa == "1" && loggedIn) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + loggedIn;
    if (!verificationRequired) {
      next();
    }
    else next("/email2fa");
  }else if (google2fa == "1" && loggedIn) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + loggedIn;
    if (!verificationRequired) {
      next();
    }
    else next("/google2fa");
  }else if (!authRequired && loggedIn) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + loggedIn;
    if (role) {
      if (role == "Admin") {
        next("/threats");
      } else if (role == "IT-Security Consultant") {
        next("/kunden");
      } else {
        next("/threat")
      }
    }
  } else {
    axios.defaults.headers.common["Authorization"] = "Bearer " + loggedIn;
    next();
  }


});

function changeTitleForPage(to){
  /**
   * Changing title per client
   */
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  const url = window.location.href;
  if(title){
    document.title = title;
  }
  else{
    if(!url.includes("78.46.206.99") && !url.includes("securious.de")){
      document.title = 'CyberXperts';
    }
    else{
      document.title = 'Securious';
    }
  }
}

function checkIsHiddenTawkTo() {
  const isTawkToHidden = localStorage.getItem("hideTawkTo");
  if (isTawkToHidden == "true") {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_API.onLoad = function () {
      window.Tawk_API["hideWidget"]();
    };
  }
}
checkIsHiddenTawkTo();
const app = createApp(App).use(i18n);
app.use(store).use(router);
app.use(Notifications);
app.use(Donut);

app.config.globalProperties.$userLanguage = 'DE';

app.directive("tooltip", {
  mounted(el, binding) {
    const showTitle = () => {
      const div = document.createElement("div");
      div.classList.add("tooltip-title");
      div.appendChild(document.createTextNode(binding.value));
      el.appendChild(div);
    };
    const remove = () => {
      let child = el.lastElementChild;
      while (child) {
        el.removeChild(child);
        child = el.lastElementChild;
      }
    };
    el.addEventListener("mouseenter", showTitle);
    el.addEventListener("mouseleave", remove);
  },
});
app.mount("#app");
