import {
  DELETE_THREAT,
  EDIT_THREAT,
  GET_THREAT_DROPDOWN,
  GET_THREATS,
  STORE_THREAT,
  UPDATE_THREAT,
  REMOVE_THREAT_IMAGE,
  IMPORT_FILE,
  ACTIVATE_THREAT,
  DUPLICATE_THREAT,
  EXPORT_THREAT,
  DRAFT_THREAT,
  DELETE_THREAT_FROM_UPDATE
} from "./actions.type";

import axios from "axios";
import {
  SET_THREAT_DROPDOWN,
  SET_EDIT_THREAT,
  SET_QUESTION_LIST,
  SET_RECOMMENDATION_LIST,
  SET_THREATS,
  SET_MENU, PROCESSING, PROCESSED,
} from "./mutations.type";
import { notify } from "@kyvg/vue3-notification";
import API_URL from "../../common/config.js";
import router from "@/router/index";
import { saveAs } from "file-saver";
import store from "..";

export const threat_module = {
  state: {
    threat: {
      image: "",
      categories: [],
      user_id: "",
      estimated_time_in_minutes: "",
      video_link: "",
      is_always_important: false,
      important_if_industry_id: false,
      important_if_company_size: false,
      is_active: false,
      show_if_industry: false,
      show_if_subscription: false,
      show_if_company_size: false,
      show_if_using_asset: false,
      package: [],
      status: "",
      friendlyTranslations: {
        EN: {
          title: "",
          description: "",
        },
        DE: {
          title: "",
          description: "",
        },
      },
      important_company_size: [0, 0],
      company_size: [0, 0],
      assets: [],
      industries: [],
      important_industry: [],
      question_dropdown: [],
      subscription_plans: []
    },
    threats: [],
    searchThreats: [],
    editThreat: [],
    industries: [],
    assets: [],
    categories: [],
    getIndustries: [],
    getAssets: [],
    getCategories: [],
    pagination: [],
    language: localStorage.getItem("LANGUAGE"),
    plans: []
  },
  actions: {
    [STORE_THREAT]({ state }, status) {
      state.threat.status = status;
      const users = localStorage.getItem("USER") ?? "4";
      const id = JSON.parse(users);
      console.log(id.id);

      state.threat.user_id = id.id;

      axios.post(API_URL + "/admin/threat", state.threat).then((response) => {

        notify({
          title: " Bedrohung erfolgreich erstellt ",
          type: "success",
        });
        state.threat = {
          image: "",
          categories: [],
          user_id: "",
          estimated_time_in_minutes: "",
          video_link: "",
          is_always_important: false,
          important_if_industry_id: false,
          important_if_company_size: false,
          is_active: false,
          show_if_industry: false,
          show_if_subscription: false,
          show_if_company_size: false,
          show_if_using_asset: false,
          status: "",
          friendlyTranslations: {
            EN: {
              title: "",
              description: "",
            },
            DE: {
              title: "",
              description: "",
            },
          },
          important_company_size: [0, 0],
          company_size: [0, 0],
          assets: [],
          industries: [],
          important_industry: [],
          question_dropdown: [],
          subscription_plans: []
        }
        if (response.data.status) {
          setTimeout(() => {
            router.push({
              name: "threatUpdate",
              params: { id: response.data.status },
            });
          }, 500);
        }
        // store.commit(SET_MENU)
      })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [UPDATE_THREAT]({ state }, status) {
      state.editThreat.status = status;
      axios
        .put(API_URL + "/admin/threat/" + state.editThreat.id, state.editThreat)
        .then((response) => {
          notify({
            title: "Bedrohung erfolgreich aktualisiert ",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [GET_THREATS](
      context,
      {
        pages,
        search = "",
        industries = [],
        services = [],
        status = "Published",
        subscription = "All",
        companySize = [],
        sortBy = "Name",
      }
    ) {
      return axios
        .get(API_URL + "/admin/threat", {
          params: {
            pages: pages,
            search: search,
            industries: industries,
            services: services,
            status: status,
            subscription: subscription,
            company_size: companySize,
            sortBy: sortBy,
          },
        })
        .then((response) => {
          context.commit(SET_THREATS, response.data.data);
        })
        .catch((err) => {
          context.commit(SET_THREATS, []);

        })
    },
    async [EDIT_THREAT](context, id) {
      await axios
        .get(API_URL + "/admin/threat/" + id + "/edit")
        .then((response) => {
          context.commit(SET_EDIT_THREAT, response.data.data);
        });
    },
    [DELETE_THREAT](context, id) {
      axios.delete(API_URL + "/admin/threat/" + id).then(() => {
        notify({
          title: " Bedrohung erfolgreich gelöscht ",
          type: "success",
        });
        // store.commit(SET_MENU)
      })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [DELETE_THREAT_FROM_UPDATE](context, id) {
      axios.delete(API_URL + "/admin/threat/" + id).then(() => {
        console.log('role', localStorage.getItem('ROLE'))
        if('Admin' == localStorage.getItem('ROLE')) {
          router.push("/threats");
          return;
        }
        router.push("/consultant/threats/edit");
        //  store.commit(SET_MENU)
      })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    async [DUPLICATE_THREAT](context, id) {
      const options = {
        headers: {"content-type": "text/plain"}
      }
      context.commit(PROCESSING);

       await axios.post(API_URL + "/admin/threat/" + id + "/duplicate", null, options).then(() => {
        notify({
          title: "Bedrohung erfolgreich dupliziert ",
          type: "success",
        });
        context.commit(PROCESSED);
      })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
          context.commit(PROCESSED);
        })
    },
    [DRAFT_THREAT]({ state }, status) {

      let data = {};

      if (status == "create") {
        data = state.threat;
      }
      else {
        data = state.editThreat;
      }

      return axios
        .post(API_URL + "/admin/threat/" + "draftThreat/", data)

    },
    [EXPORT_THREAT]() {
      axios
        .post(API_URL + "/admin/threat/export", { responseType: "arraybuffer" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "octet/stream" });
          const fileName = "threats.zip";
          saveAs(blob, fileName);
        }).catch((err) => {
          console.log(err);

        })
    },
    [GET_THREAT_DROPDOWN](context) {
      axios.get(API_URL + "/admin/threat/create").then((response) => {
        console.log(response);

        context.commit(SET_THREAT_DROPDOWN, response);
      });
    },
    [REMOVE_THREAT_IMAGE]({ state }) {
      axios.post(API_URL + "/admin/threat/deleteImage/" + state.editThreat.id);
    },
    [IMPORT_FILE](context, file) {
      axios
        .post(API_URL + "/admin/threat/import", { backup_file: file })
        .then((response) => {
          notify({
            title: " Bedrohung erfolgreich importiert ",
            type: "success",
          });

        }).catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [ACTIVATE_THREAT](context, { id, is_active }) {
      axios
        .post(API_URL + "/admin/toggle_threat", {
          id,
          is_active,
        })
        .then(() => {
          notify({
            title: "Bedrohungsstatus erfolgreich aktualisiert",
            type: "success",
            text: is_active ? "Aktiviert" : "Deaktiviert",
          });
        })
        .catch(() => {
          notify({
            title: "Bedrohungsaktualisierung fehlgeschlagen ",
            type: "error",
          });
        });
    },
  },
  mutations: {
    [SET_THREATS](state, data) {
      state.threats = data.items;
      state.pagination = data.pagination;
    },
    [SET_EDIT_THREAT](state, data) {
      state.editThreat = data;
      state.editThreat.subscription_plans = data?.subscription_plans?.map((dat)=> dat.product_id);
    },
    [SET_QUESTION_LIST](state, data) {
      state.editThreat.analysisQuestion = data;
    },
    [SET_RECOMMENDATION_LIST](state, data) {
      state.editThreat.recommendation = data;
    },
    [SET_THREAT_DROPDOWN](state, response) {
      const language = localStorage.getItem("LANGUAGE");
      state.industries = response.data.data[0].items;
      state.assets = response.data.data[1].items;
      state.categories = response.data.data[2].items;
      state.getIndustries = state.industries.map((industry) => {
        return {
          id: industry["id"],
          name: industry["friendlyTranslations"][language + ".title"]?.value,
        };
      });
      state.getAssets = state.assets.map((asset) => {
        return {
          id: asset["id"],
          name: asset["friendlyTranslations"][language + ".title"]?.value,
        };
      });
      state.getCategories = state.categories.map((category) => {
        return {
          id: category["id"],
          name: category["friendlyTranslations"][language + ".title"]?.value,
        };
      });
      state.plans = response.data.data[3];
    },
  },
};
