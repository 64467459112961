import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/views/login-page-securious.svg'
import _imports_1 from '../assets/views/login-page-cyber-experts.svg'


const _withScopeId = n => (_pushScopeId("data-v-3e2328ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid grid-cols-2 login md:grid-cols-1 sm:grid-cols-1" }
const _hoisted_2 = { class: "left_content" }
const _hoisted_3 = { class: "flex sm:justify-center" }
const _hoisted_4 = { class: "my-0 mx-auto pt-5 px-14 pb-10 flex flex-col md:mt-6 max-w-xl md:w-full sm:px-4" }
const _hoisted_5 = { class: "form-title text-xxxl sm:text-medium sm:text-center" }
const _hoisted_6 = { class: "relative" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "link-text text-right text-base cursor-pointer" }
const _hoisted_10 = {
  key: 0,
  class: "text-base text-red-red"
}
const _hoisted_11 = { class: "grid grid-cols-4" }
const _hoisted_12 = { class: "login-btn col-start-2 col-end-4 rounded-lg text-white text-md border-2 border-grey-grey border-opacity-40" }
const _hoisted_13 = {
  key: 0,
  class: "register flex justify-center space-x-1 pt-2"
}
const _hoisted_14 = { class: "text-base text-grey-grey" }
const _hoisted_15 = { class: "text-base link-text font-semibold" }
const _hoisted_16 = { class: "footer-link link-text text-base flex justify-center mt-8 space-x-4 md:right-1/4 sm:right-1/4" }
const _hoisted_17 = { class: "cursor-pointer" }
const _hoisted_18 = {
  key: 0,
  href: "https://securious.de/impressum",
  target: "_"
}
const _hoisted_19 = {
  key: 1,
  href: "https://cyberxperts.de/impressum.html",
  target: "_"
}
const _hoisted_20 = { class: "cursor-pointer" }
const _hoisted_21 = {
  key: 0,
  href: "https://securious.de/datenschutz/",
  target: "_"
}
const _hoisted_22 = {
  key: 1,
  href: "https://cyberxperts.de/datenschutz.html",
  target: "_"
}
const _hoisted_23 = { class: "bg-login-background bg-no-repeat bg-center items-start p-0 flex justify-center h-screen md:hidden sm:hidden" }
const _hoisted_24 = { class: "right_content flex pr-1 ml-2 pl-2" }
const _hoisted_25 = {
  key: 0,
  alt: "Securious login page image",
  class: "justify-center",
  src: _imports_0
}
const _hoisted_26 = {
  key: 1,
  alt: "CyberXperts login page image",
  class: "justify-center",
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, {
          isSecurious: _ctx.securious,
          classes: "h-login-logo sm:my-8 md:mt-16 md:ml-6 sm:ml-0 med:m-10 lg:m-14 xl:m-16",
          dark: true
        }, null, 8, ["isSecurious"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('Login')), 1),
        _createVNode(_component_Form, {
          "validation-schema": $setup.schema,
          class: "flex flex-col space-y-3 mt-4 sm:mt-8",
          onSubmit: $options.handleSubmit
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Field, {
              id: "email",
              class: "inp rounded-none p-2 text-xs",
              name: "email",
              placeholder: _ctx.$t('Email'),
              type: "email"
            }, null, 8, ["placeholder"]),
            _createVNode(_component_ErrorMessage, {
              class: "text-base text-red-red",
              name: "email"
            }),
            _createElementVNode("div", _hoisted_6, [
              ($setup.showPassword)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_Field, {
                      id: "password",
                      class: "inp rounded-none p-2 text-xs",
                      name: "password",
                      placeholder: _ctx.$t('Password'),
                      type: "text"
                    }, null, 8, ["placeholder"]),
                    ($setup.showPassword)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "text-grey-grey text-base fa fa-fw field-icon absolute right-2 top-3 fa-eye",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggleShow && $setup.toggleShow(...args)))
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_Field, {
                      id: "password",
                      class: "inp rounded-none p-2 text-xs",
                      name: "password",
                      placeholder: _ctx.$t('Password'),
                      type: "password"
                    }, null, 8, ["placeholder"]),
                    _createElementVNode("span", {
                      class: "text-grey-grey text-base fa fa-fw field-icon absolute right-2 top-3 fa-eye-slash",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.toggleShow && $setup.toggleShow(...args)))
                    })
                  ]))
            ]),
            _createVNode(_component_ErrorMessage, {
              class: "text-base text-red-red",
              name: "password"
            }),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_router_link, { to: "/password/reset" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Forgot your password')), 1)
                ]),
                _: 1
              })
            ]),
            ($setup.backendError.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($setup.backendError), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('Login')), 1)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema", "onSubmit"]),
        (_ctx.securious)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('No account yet')), 1),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_router_link, { to: { name: 'Register' } }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Register')), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          (_ctx.securious)
            ? (_openBlock(), _createElementBlock("a", _hoisted_18, _toDisplayString(_ctx.$t('Imprint')), 1))
            : (_openBlock(), _createElementBlock("a", _hoisted_19, _toDisplayString(_ctx.$t('Imprint')), 1))
        ]),
        _createElementVNode("div", _hoisted_20, [
          (_ctx.securious)
            ? (_openBlock(), _createElementBlock("a", _hoisted_21, _toDisplayString(_ctx.$t('Data privacy')), 1))
            : (_openBlock(), _createElementBlock("a", _hoisted_22, _toDisplayString(_ctx.$t('Data privacy')), 1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        (_ctx.securious)
          ? (_openBlock(), _createElementBlock("img", _hoisted_25))
          : (_openBlock(), _createElementBlock("img", _hoisted_26))
      ])
    ])
  ]))
}