import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../assets/views/securious-white-logo.svg'
import _imports_1 from '../assets/views/securious-logo.svg'
import _imports_2 from '../assets/cyber-xperts-logo-white.svg'
import _imports_3 from '../assets/logo-dark.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        (_ctx.isSecurious && !_ctx.dark)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              alt: "Securious",
              src: _imports_0,
              class: _normalizeClass(_ctx.classes),
              style: _normalizeStyle(_ctx.styless)
            }, null, 6))
          : _createCommentVNode("", true),
        (_ctx.isSecurious && _ctx.dark)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              alt: "Securious",
              src: _imports_1,
              class: _normalizeClass(_ctx.classes),
              style: _normalizeStyle(_ctx.styless)
            }, null, 6))
          : _createCommentVNode("", true),
        (!_ctx.isSecurious && !_ctx.dark)
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              alt: "CyberXperts",
              src: _imports_2,
              class: _normalizeClass(_ctx.classes),
              style: _normalizeStyle(_ctx.styless)
            }, null, 6))
          : _createCommentVNode("", true),
        (!_ctx.isSecurious && _ctx.dark)
          ? (_openBlock(), _createElementBlock("img", {
              key: 3,
              alt: "CyberXperts",
              src: _imports_3,
              class: _normalizeClass(_ctx.classes),
              style: _normalizeStyle(_ctx.styless)
            }, null, 6))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}