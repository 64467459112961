import router from "@/router";
import axios from "axios";
import API_URL from "../../common/config";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  RESEND_VERIFICATION,
  CHECK_AUTH,
  VERIFY,
  GET_REFERRAL_DETAILS,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  GET_CUSTOMER_SETTING,
  SALES_LOGIN,
  SALES_INVITE,
  GET_SALES_INVITE_EMAIL,
  IMPERSONATE,
  STOP_IMPERSONATE,
  GET_MENU, REGISTER_CONSULTANT,
} from "./actions.type";
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_LOGINERROR,
  SET_REGISTERERROR,
  SET_VERIFYERROR,
  SET_FORGOTPASSWORDERROR,
  SET_FORGOTPASSWORD,
  SET_RESETPASSWORDERROR,
  SET_RESETPASSWORD,
  SET_MENU,
  SET_SALES_AUTH,
  RESET_AUTH_MESSAGES,
  PROCESSING,
  PROCESSED
} from "./mutations.type";
import popupService from "../../service/popup.service";
import { notify } from "@kyvg/vue3-notification";
const { onError, onBackendError, onSuccessMessage } = popupService();
export const auth_module = {
  state: {
    user: localStorage.getItem("USER"),
    error: [],
    success: [],
    role: "",
    menu: localStorage.getItem("MENU"),
    consultant: {},
    salesUser: [],
    salesEmail: [],
    points: {},

  },
  actions: {
    [GET_MENU]: async function ({ commit }, payload) {

      return axios.post(API_URL + "/get-menu", { id: payload })
        .then((res) => {
          commit("GET_MENU", res.data)
        })
        .catch((err) => {
          console.log(err);

        })
    },
    GET_POINTS: async function ({ commit }, payload) {

      const userData = JSON.parse(localStorage.getItem('USER') ?? "user") ?? "";
      const id = userData.id;
      const res = await axios.get(API_URL + "/customer/points/" + id).then((res) => {
        const data = {
          "obtainedPoints": res.data.obtainedPoints,
          "progress": res.data.progress,
          "totalPoints": res.data.totalPoints,
        }

        commit("SET_POINTS", data);
      })

    },
    CLEAR_MENU: function ({ commit }) {
      commit("CLEAR_MENUS");
    },
    async [LOGIN]({ state, commit }, credentials) {
      await axios
        .post(API_URL + "/login", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          console.log(response);

          console.log(response.data.status);
          if (response.data.status == 404) {
            commit(SET_LOGINERROR, response);
          } else {
            commit(SET_AUTH, response);
            commit(SET_MENU, response.data.data.resource.menus);
            if (state.role == "Admin")
              router.push({ name: "threats" });
            else if (state.role == "IT-Security Consultant")
              router.push({ name: "kunden" });
            else router.push({ name: "customerThreat" });
          }

        })
        .catch((error) => {
          commit(SET_LOGINERROR, error);
        });
    },
    [IMPERSONATE]({ state, commit }, id) {
      return axios
        .post(API_URL + "/admin/impersonate", { id: id })
        .then((response) => {
          const token = localStorage.getItem("ID_TOKEN_KEY");
          const user = localStorage.getItem("USER");
          const company = response.data.data.resource.company;
          const role = response.data.data.resource.role[0].role;
          token ? localStorage.setItem("IMPERSONATOR_TOKEN", token) : null;
          user ? localStorage.setItem("IMPERSONATOR_USER", user) : null;

          commit(SET_MENU, response.data.data.resource.menus);

          localStorage.setItem("COMPANY", (company == null) ? "0" : company.id);

          localStorage.setItem(
            "ID_TOKEN_KEY",
            response.data.data.resource.token.plainTextToken
          );

          localStorage.setItem(
            "USER",
            JSON.stringify(response.data.data.resource)
          );

          localStorage.setItem("ROLE", role)

          localStorage.setItem("MENU", JSON.stringify(response.data.data.resource.menus))

          if (role == 'IT-Security Consultant')
            router.push({ name: "kunden" });
          else
            router.push({ name: "customerThreat" });
        })
        .catch((err) => {
          localStorage.clear();
          console.log(err);

        })
        ;
    },
    [STOP_IMPERSONATE](context) {
      const user = localStorage.getItem("IMPERSONATOR_USER") ?? '';
      const token = localStorage.getItem("IMPERSONATOR_TOKEN");
      const role = localStorage.getItem("ROLE");
      token ? localStorage.setItem("ID_TOKEN_KEY", token) : null;

      if (user) {
        localStorage.setItem("USER", user)
        localStorage.setItem("ROLE", JSON.parse(user).role[0].role)
        context.commit(SET_MENU, JSON.parse(user).menus)
      }


      localStorage.removeItem("IMPERSONATOR_TOKEN");
      localStorage.removeItem("IMPERSONATOR_USER");

      if(null !== user){
        const $user = JSON.parse(user)
        if($user.role[0].role == 'IT-Security Consultant'){
          return router.push({ name: "kunden" });
        }
      }

      if (role == 'IT-Security Consultant')
        router.push({ name: "consultants" });
      else
        router.push({ name: "companies" });
    },
    [SALES_LOGIN]({ state, commit }, credentials) {
      return axios
        .post(API_URL + "/sales-login", {
          email: 'sales@securious.de',
          password: credentials.password,
        })
        .then((response) => {
          commit(SET_SALES_AUTH, response)
          router.push({ name: "sales.invite" });
        })
        .catch((error) => {
          throw error;
          // commit(SET_LOGINERROR, error);
        });
    },
    [GET_REFERRAL_DETAILS](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(PROCESSING);
        axios.get(API_URL + "/get-referral/", {params : payload})
            .then((response) => {
              context.commit(PROCESSED);
              resolve(response.data);
              // state.role = response.data.data.role; //not settings role for now
              // state.consultant = response.data.data.consultant; //not settings role for now
            })
            .catch((err) => {
              context.commit(PROCESSED);
              reject(err.response.data);
            });
      })
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
    },
    [CHECK_AUTH]() {
      if (localStorage.getItem("ID_TOKEN_KEY")) return true;
      else return false;
    },
    [REGISTER](context, payload) {
      // credentials.role = role.value; //no role is to be provided from frontend
      // credentials.ref = referer; //no referrer is to be provided for now, todo : will see it later
      return new Promise((resolve, reject) => {
        context.commit(PROCESSING);
        axios.post(API_URL + "/register",payload)
            .then((response) => {
              context.commit(PROCESSED);
              resolve(response.data);
            })
            .catch((error) => {
              context.commit(PROCESSED);
              reject(error);
            });
      });
    },
    [REGISTER_CONSULTANT](context, payload) {
      // credentials.role = role.value; //no role is to be provided from frontend
      // credentials.ref = referer; //no referrer is to be provided for now, todo : will see it later
      return new Promise((resolve, reject) => {
        context.commit(PROCESSING);
        axios.post(API_URL + "/consultant/register",payload)
            .then((response) => {
              context.commit(PROCESSED);
              resolve(response.data);
            })
            .catch((error) => {
              context.commit(PROCESSED);
              reject(error);
            });
      });
    },
    async [VERIFY](context, code) {
      const user = localStorage.getItem("USER");
      const email = user ? JSON.parse(user).email : "";

      await axios
        .post(API_URL + "/verification", {
          email: email,
          code: code,
        })
        .then((response) => {
          localStorage.setItem("COMPANY", "0");
          localStorage.setItem("EMAIL_VERIFIED_AT", response.data);
          router.push("/introduction");
        })
        .catch((error) => {
          context.commit(SET_VERIFYERROR, error);
        });
    },
    [FORGOT_PASSWORD](context, data) {
      axios
        .post(API_URL + "/forgot-password", {
          email: data.email,
        })
        .then((response) => {
          context.commit(SET_FORGOTPASSWORD, response);
        })
        .catch((error) => {
          context.commit(SET_FORGOTPASSWORDERROR, error);
        });
    },
    [RESET_PASSWORD](context, data) {
      axios
        .post(API_URL + "/reset-password", {
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          token: data.token,
        })
        .then((response) => {
          context.commit(SET_RESETPASSWORD, response);
        })
        .catch((error) => {
          context.commit(SET_RESETPASSWORDERROR, error);
        });
    },
    [CHANGE_PASSWORD](context, data) {
      axios
        .post(API_URL + "/admin/users/update_password", {
          id: data.id,
          password: data.password,
          password_confirmation: data.password_confirmation,
        })
        .then((response) => {
          context.commit(SET_RESETPASSWORD, response);
        })
        .catch((error) => {
          context.commit(SET_RESETPASSWORDERROR, error);
        });
    },
    [SALES_INVITE](context, data) {
      return axios.post(API_URL + '/sales-invite', data)
        .then((response) => {
          notify({
            title: response.data.message,
            type: response.data.status == 200 ? "success" : "failed"
          })
        }).catch((err) => {
          console.log(err);
        })
    },

    [RESEND_VERIFICATION]({ state }, email) {
      return axios.get(API_URL + '/resend-verification/' + email)
    },
  },
  mutations: {
    [RESET_AUTH_MESSAGES]: function (state) {
      state.error = [];
      state.success = [];
    },
    SET_POINTS: function (state, data) {
      state.points = data;
      localStorage.setItem("POINTS", JSON.stringify(data));
    },
    GET_MENU: function (state, data) {
      localStorage.setItem("MENU", JSON.stringify(data));
      state.menu = localStorage.getItem("MENU")
    },
    CLEAR_MENUS: function (state) {
      localStorage.clear();
      state.menu = [];
    },
    [SET_AUTH](state, user) {
      state.role = user.data.data.resource.role[0].role;

      if (state.role == "Admin") {
        localStorage.setItem("hideTawkTo", "true");
      }

      localStorage.setItem("USER", JSON.stringify(user.data.data.resource));
      localStorage.setItem("ID_TOKEN_KEY", user.data.data.resource.token);

      if (user.data.data.resource.menus)
        localStorage.setItem("MENU", JSON.stringify(user.data.data.resource.menus));

      localStorage.setItem(
        "LANGUAGE",
        user.data.data.resource.selected_language
      );

      localStorage.setItem(
        "EMAIL2FA",
        user.data.data.resource.email_2fa_enabled
      );

      localStorage.setItem(
        "GOOGLE2FA",
        user.data.data.resource.google_2fa_enabled
      );

      localStorage.setItem("ROLE", state.role);

      //set user company
      const company = user.data.data.resource.company;
      localStorage.setItem("COMPANY", (company == null) ? "0" : company.id);

      if (user.data.data.resource.email_verified_at)
        localStorage.setItem(
          "EMAIL_VERIFIED_AT",
          user.data.data.resource.email_verified_at
        );
    },
    [PURGE_AUTH](state) {
      localStorage.clear();
      router.push("/login");
    },
    [SET_REGISTERERROR](state, error) {
      onBackendError(error);
    },
    [SET_FORGOTPASSWORDERROR](state, error) {
      if (error.response) {
        state.error = error.response.data.errors;
      }
      onBackendError(error);
    },
    [SET_RESETPASSWORDERROR](state, error) {
      if (error.response) {
        state.error = error.response.data.errors;
      }
      onBackendError(error);
    },
    [SET_FORGOTPASSWORD](state, res) {
      state.error = [];
      state.success = res.data;
      onSuccessMessage(res);
    },
    [SET_RESETPASSWORD](state, res) {
      state.error = [];
      state.success = res.data;
      onSuccessMessage(res);
    },
    [SET_LOGINERROR](state, error) {

      if (error.data) {
        state.error = error.data.message;
      }
    },
    [SET_VERIFYERROR](state, error) {
      onError(error);
    },
    [SET_MENU](state, menu) {
      state.menu = JSON.stringify(menu);
    },
    [SET_SALES_AUTH](state, user) {
      state.salesUser = user.data.data.resource;
      const company = user.data.data.resource.company;
      localStorage.setItem("COMPANY", (company == null) ? "0" : company.id);

      localStorage.setItem("SALES_ID_TOKEN_KEY", user.data.data.resource.token);

      localStorage.setItem(
        "EMAIL_VERIFIED_AT",
        user.data.data.resource.email_verified_at
      );
    }
  },
};
