import axios from "axios";
import {DELETE_INDUSTRY, GET_INDUSTRIES, NEW_INDUSTRY, STORE_INDUSTRY, TOGGLE_STATUS,} from "./actions.type";
import API_URL from "../../common/config";
import {ADD_INDUSTRIES, CAN_ADD, REMOVE_INDUSTRY, SET_INDUSTRIES,} from "./mutations.type";
import {notify} from "@kyvg/vue3-notification";

export const industry_module = {
  state: {
    industries: [],
    pagination: [],
    canAdd: true,
  },
  actions: {
    [GET_INDUSTRIES](context, { page, search=''} ) {
      axios.get(API_URL + "/admin/industries", {params: { pages: page, search: search }}).then((response) => {
        context.commit(SET_INDUSTRIES, response);
      }).catch((err)=>{
        console.log(err);
        
      })
    },
    [STORE_INDUSTRY](context, industry) {
      if (industry.id)
        axios
          .put(API_URL + "/admin/industries/" + industry.id, industry)
          .then((response) => {
            context.dispatch(GET_INDUSTRIES, { page: 8 })
            notify({
              title: " Branche erfolgreich aktualisiert",
              type: "success"
            })
          })
          .catch((response) => {
            notify({
              title: " Etwas ist schief gegangen ",
              type: "fail"
            })
          })
      else
        axios.post(API_URL + "/admin/industries", industry)
        .then((response) => {
          context.commit(CAN_ADD,true)
          context.dispatch(GET_INDUSTRIES, { page: 8 })
          notify({
            title: "Branche erfolgreich erstellt",
            type: "success"
          })
        })
        .catch((response) => {
          context.commit(CAN_ADD,true)
          notify({
            title: " Etwas ist schief gegangen ",
            type: "fail"
          })
        })
    },
    [NEW_INDUSTRY](context, industry) {
      context.commit(ADD_INDUSTRIES, industry);
      notify({
        title: "Branche erfolgreich erstellt",
        type: "success",
        text: "Request: <b>completed</b>"
      })
    },
    async [DELETE_INDUSTRY] (context, { id,index }){
      if (id)
        await axios.delete(API_URL + '/admin/industries/' + id)
            .then(() => {
              context.commit(REMOVE_INDUSTRY, index)
              notify({
                title: "Branche erfolgreich gelöscht",
                type: "success",
                text: "Request: <b>completed</b>"
              })
            })
            .catch(error => {
              notify({
                title: error.response.data.message,
                type: "error",
              })
            })
      else {
        context.commit(CAN_ADD, true)
      }
    },
    [TOGGLE_STATUS] (context,{id,is_active}) {
      axios.post(API_URL + '/admin/industries/toggleStatus',{id,is_active}).then(() => {
        notify({
          title: " Branche erfolgreich aktualisiert ",
          type: "success",
          text: is_active ? "Aktiviert" : "Deaktiviert"
        })
      })
    }
  },
  mutations: {
    [SET_INDUSTRIES](state, response){
      state.industries = response.data.data.items
      state.pagination = response.data.data.pagination
    },
    [ADD_INDUSTRIES](state, data) {
      state.industries.unshift(data);
    },
    [REMOVE_INDUSTRY] (state,index){
      state.industries.splice(index,1)
    },
    [CAN_ADD] (state,value){
        state.canAdd = value
    }
  },
};
