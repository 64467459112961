import axios from "axios";
import router from "@/router";
import API_URL from "../../common/config";
import {
  GET_EDIT_RECOMMENDATION,
  GET_RECOMMENDATION_DROPDOWN,
  STORE_RECOMMENDATION,
  DELETE_RECOMMENDATION,
  UPDATE_RECOMMENDATION,
  GET_CONSULTANT_RECOMMENDATION_DROPDOWN,
  STORE_CONSULTANT_RECOMMENDATION,
  GET_CONSULTANT_EDIT_RECOMMENDATION,
  UPDATE_CONSULTANT_RECOMMENDATION,
  UPDATE_RECOMMENDATION_STATUS
} from "./actions.type";
import {
  SET_EDIT_RECOMMENDATION_ANSWERS,
  ADD_POSSIBLE_RECOMMENDATION,
  SET_EDIT_RECOMMENDATION,
  SET_RECOMMENDATION_ANSWER,
  SET_RECOMMENDATION_DROPDOWN,
  ADD_RECOMMENDATION_DESCRIPTION_FOR_ASSETS,
  SET_RECOMMENDATION_DESCRIPTION_FOR_ASSETS,
  DELETE_RECOMMENDED_DESCRIPTION_FOR_ASSETS,
  ADD_QUESTION_ANSWER,
  DELETE_RECOMMENDATION_QUESTION_ANSWER,
  ADD_RECOMMENDATION_EDIT_QUESTION_ANSWER,
  SET_EDIT_RECOMMENDATION_DESCRIPTION_FOR_ASSETS,
  DELETE_RECOMMENDATION_EDIT_QUESTION_ANSWER,
  ADD_RECOMMENDATION_EDIT_DESCRIPTION,
  DELETE_RECOMMENDATION_EDIT_DESCRIPTION, GOTO_THREAT_EDIT
} from "./mutations.type";
import { notify } from "@kyvg/vue3-notification";
import { useRoute } from "vue-router";

export const recommendation_module = {
  state: {
    recommendation: {
      company_size: [0, 0],
      assets: [],
      industries: [],
      is_automated: false,
      points: "",
      show_if_industry: false,
      show_if_company_size: false,
      display_if_conditions: false,
      threat_id: "",
      order: "0",
      status: "",
      friendlyTranslations: {
        EN: {
          title: "",
          one_sentence_recommendation: "",
          description: "",
        },
        DE: {
          title: "",
          one_sentence_recommendation: "",
          description: "",
        },
      },
      question_answer: [
        {
          question_id: "",
          answers: [],
        }
      ],
      questions: [],
      solution_partner: [],
      description: []
    },
    questionsList: [],
    questions: [],
    answers: [],
    industries: [],
    assets: [],
    solutionPartners: [],
    editRecommendation: [],
    language: localStorage.getItem("LANGUAGE"),
  },
  actions: {
    [STORE_RECOMMENDATION]({ state }, status) {
      state.recommendation.threat_id = router.currentRoute.value.params.id;
      state.recommendation.status = status;
      axios
        .post(API_URL + "/admin/recommendation", state.recommendation)
        .then((response) => {
          notify({
            title: "Empfehlung erfolgreich hinzugefügt",
            type: "success",
          });
          router.push({name: 'editThreatRecommendation', params: {id: response.data.data.id}})

          state.recommendation = {
            company_size: [0, 0],
            assets: [],
            industries: [],
            is_automated: false,
            points: "",
            show_if_industry: false,
            show_if_company_size: false,
            display_if_conditions: false,
            threat_id: "",
            order: "0",
            status: "",
            friendlyTranslations: {
              EN: {
                title: "",
                one_sentence_recommendation: "",
                description: "",
              },
              DE: {
                title: "",
                one_sentence_recommendation: "",
                description: "",
              },
            },
            question_answer: [
              {
                question_id: "",
                answers: [],
              }
            ],
            questions: [],
            solution_partner: [],
            description: []
          }
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [UPDATE_RECOMMENDATION_STATUS]({ state }, payload) {
      axios
        .post(API_URL + "/update-recommendation/" + payload.id, payload)
        .then(() => {
          notify({
            title: "Empfehlungsstatus ist aktualisiert",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [STORE_CONSULTANT_RECOMMENDATION]({ state }, status) {
      state.recommendation.threat_id = router.currentRoute.value.params.id;
      state.recommendation.status = status;
      axios
        .post(API_URL + "/consultant/save-recommendation", state.recommendation)
        .then(() => {
          notify({
            title: "Empfehlung erfolgreich hinzugefügt",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [GET_RECOMMENDATION_DROPDOWN](context, threat_id) {
      return axios
        .get(API_URL + "/admin/recommendation/create", {
          params: { threat_id: threat_id },
        })
        .then((response) => {
          context.commit(SET_RECOMMENDATION_DROPDOWN, response.data.data);
        });
    },
    [GET_CONSULTANT_RECOMMENDATION_DROPDOWN](context, threat_id) {
      axios
        .get(API_URL + "/consultant/recommendation-dropdown/" + threat_id)
        .then((response) => {
          context.commit(SET_RECOMMENDATION_DROPDOWN, response.data.data);
        });
    },
    [GET_EDIT_RECOMMENDATION](context, id) {
      return axios
        .get(API_URL + "/admin/recommendation/" + id + "/edit")
        .then((response) => {
          context.commit(SET_EDIT_RECOMMENDATION, response.data.data);
        });
    },
    [GET_CONSULTANT_EDIT_RECOMMENDATION](context, id) {
      return axios
        .get(API_URL + "/consultant/recommendation/" + id)
        .then((response) => {
          context.commit(SET_EDIT_RECOMMENDATION, response.data.data);
        });
    },
    [UPDATE_RECOMMENDATION]({ state }, status) {
      state.editRecommendation.status = status;
      axios
        .put(
          API_URL + "/admin/recommendation/" + state.editRecommendation.id,
          state.editRecommendation
        )
        .then(() => {
          notify({
            title: "Empfehlung wurde aktualisiert",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [UPDATE_CONSULTANT_RECOMMENDATION]({ state }, status) {
      state.editRecommendation.status = status;
      axios
        .put(
          API_URL + "/consultant/update-recommendation/" + state.editRecommendation.id,
          state.editRecommendation
        )
        .then(() => {
          notify({
            title: "Empfehlung wurde aktualisiert",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [DELETE_RECOMMENDATION]({ state, commit }, { status, id }) {
      state.editRecommendation.status = status;
      if (id)
        axios.delete(API_URL + "/admin/recommendation/" + id).then(() => {
          notify({
            title: "Empfehlung erfolgreich gelöscht",
            type: "success",
          });
        })
          .catch(() => {
            notify({
              title: "Etwas ist schief gelaufen",
              type: "fail",
            });
          })
      else
        axios
          .delete(
            API_URL + "/admin/recommendation/" + state.editRecommendation.id
          )
          .then(() => {
            notify({
              title: "Empfehlung erfolgreich gelöscht",
              type: "success",
            });
            router.push({ name: 'threatUpdate', params: { id: state.editRecommendation.threat_id }, query: {tab: 'recommendation'} })
          })
          .catch(() => {
            notify({
              title: "Etwas ist schief gelaufen",
              type: "fail",
            });
          })
    },
  },
  mutations: {
    [SET_EDIT_RECOMMENDATION_ANSWERS](context, data) {
      console.log('data', data)
      console.log( data.editRecommendation.questionAnswers)
      context.answers = [];
      data.editRecommendation.questionAnswers.forEach((question_answer, index) => {
        console.log('question_answer',question_answer)

        const answers = data.questions.filter((question) => question.id == question_answer.id);
        console.log('answers',answers)

        context.answers.push(answers[0]?.answers.map((answer) => {
          return {
            id: answer["id"],
            name: answer["friendlyTranslations"][context.language + ".title"][
                "value"
                ],
          };
        }));
      });


      // context.commit()
    },
    [GOTO_THREAT_EDIT](state, data) {
      router.push({ name: "threatUpdate", params: { id: data } });
    },
    [SET_RECOMMENDATION_DROPDOWN](state, data) {
      state.questions = data.questionAnswer;
      state.questionsList = data.questionAnswer.map((question) => {
        return {
          id: question["id"],
          name: question["friendlyTranslations"][state.language + ".title"][
            "value"
          ],
        };
      });
      state.industries = data.industries.items.map((industry) => {
        return {
          id: industry["id"],
          name: industry["friendlyTranslations"][state.language + ".title"][
            "value"
          ],
        };
      });
      state.assets = data.assetPartners.items.map((asset) => {
        return {
          id: asset["id"],
          name: asset["friendlyTranslations"][state.language + ".title"][
            "value"
          ],
        };
      });
    },
    [SET_RECOMMENDATION_ANSWER](state, data) {
      console.log(data);

      /**
       * To empty the answers selection whenever a question is changed
       */
      //only work for recommendation edit screen
      if( state.editRecommendation.length !== 0 ) {
        state.editRecommendation.questionAnswers[data.index].answers = null;
      }
      else{
        state.recommendation.question_answer[data.index].answers = null;
      }

      const answers = state.questions.filter((question) => question.id == data.id);

      state.answers[data.index] = answers[0]?.answers.map((answer) => {
        return {
          id: answer["id"],
          name: answer["friendlyTranslations"][state.language + ".title"][
            "value"
          ],
        };
      });
    },
    [SET_EDIT_RECOMMENDATION](state, data) {
      state.editRecommendation = data;
    },
    [ADD_POSSIBLE_RECOMMENDATION](state, recommendation) {
      state.question.editQuestion.recommendation.push(recommendation);
    },
    [ADD_RECOMMENDATION_DESCRIPTION_FOR_ASSETS](state) {
      const description = {
        "description": "",
        "solution_partner_product_id": [],
        "friendlyTranslations": {
          EN: {
            description: "",
          },
          DE: {
            description: "",
          },
        }
      }

      state.recommendation.description.push(description);
    },
    [SET_RECOMMENDATION_DESCRIPTION_FOR_ASSETS](state, data) {
      state.recommendation.description[data.id].friendlyTranslations[state.language].description = data.description
    },
    [DELETE_RECOMMENDED_DESCRIPTION_FOR_ASSETS](state, index) {
      state.recommendation.description.splice(index, 1)
    },
    [ADD_QUESTION_ANSWER](state) {
      state.recommendation.question_answer.push(
        {
          question_id: "",
          answers: [],
        }
      );
    },
    [DELETE_RECOMMENDATION_QUESTION_ANSWER](state, index) {
      state.recommendation.question_answer.splice(index, 1);
      state.answers.splice(index, 1);
    },

    [ADD_RECOMMENDATION_EDIT_QUESTION_ANSWER](state) {
      state.editRecommendation.questionAnswers.push(
        {
          id: "",
          friendlyTranslations: {
            "DE.title": {
              id: "",
              title: "",
              value: ""
            },
            "EN.title": {
              id: "",
              title: "",
              value: ""
            }
          },
          answers: [],

        }
      );
    },
    [DELETE_RECOMMENDATION_EDIT_QUESTION_ANSWER](state, index) {
      state.editRecommendation.questionAnswers.splice(index, 1);
    },
    [SET_EDIT_RECOMMENDATION_DESCRIPTION_FOR_ASSETS](state, data) {
      state.editRecommendation.description_for[data.id].friendlyTranslations[state.language + ".description"].value = data.description
    },
    [ADD_RECOMMENDATION_EDIT_DESCRIPTION](state) {
      const description = {
        "friendlyTranslations": {
          "DE.description": {
            id: "",
            name: "",
            value: "",
          },
          "EN.description": {
            id: "",
            name: "",
            value: "",
          },
        },
        "id": "",
        "solution_partner_product_id": [],

      };

      state.editRecommendation.description_for.push(description);
    },
    [DELETE_RECOMMENDATION_EDIT_DESCRIPTION](state, index) {
      state.editRecommendation.description_for.splice(index, 1);
    }
  },
};
