import router from "@/router";
import axios from "axios";
import API_URL from "../../common/config";
import {
  GET_INTRO_DROPDOWN,
  STORE_INTRODUCTION,
  GET_GREETING,
  GET_EMAILADDRESS,
  GET_INTRO_INVITE_DETAILS,
} from "./actions.type";
import {
  SET_INTRO_DROPDOWN,
  SET_GREETING,
  SET_EMAIL_ADDRESS,
  SET_INTRO_INVITE_DETAILS,
} from "./mutations.type";
import { notify } from "@kyvg/vue3-notification";

export const introduction_module = {
  state: {
    intro: {
      company: {
        company_name: "",
        company_website: "",
        company_size: "",
        business_address: "",
        company_role_id: "",
        legal_role_id: "",
        industry_id: "",
      },
      profile: {
        title: "mr",
        first_name: "",
        last_name: "",
      },
    },
    countries: [],
    roles: [],
    legal_roles: [],
    industries: [],
    language: localStorage.getItem("LANGUAGE"),
    greeting: null,
    email: null,
  },
  actions: {
    [GET_INTRO_DROPDOWN](context) {
      return axios.get(API_URL + "/introduction").then((response) => {
        context.commit(SET_INTRO_DROPDOWN, response.data.data);
      })
      .catch((err)=>{
        console.log(err);
        
      })
    },
    [GET_INTRO_INVITE_DETAILS](context, email) {
      return axios.get(API_URL + "/sales-invite/?email=" + encodeURIComponent(email) ).then((response) => {
        context.commit(SET_INTRO_INVITE_DETAILS, response.data.data);
      })
      .catch((err)=>{
        console.log(err);
      })
    },
    [STORE_INTRODUCTION]({ commit, state }) {
      axios
        .post(API_URL + "/introduction", state.intro)
        .then((response) => {
          localStorage.setItem("COMPANY", "1");

          /**
           * Insert company for user
           */
          const user = JSON.parse(localStorage.getItem('USER') ?? "user") ?? "";
          user.company_id = response.data.data.company.id;
          user.company = response.data.data.company;
          user.first_name =state.intro.profile.first_name;
          user.last_name =state.intro.profile.last_name;
          user.name = state.intro.profile.first_name + ' ' +  state.intro.profile.last_name;
          user.company_name = response.data.data.company.company_name;
          localStorage.setItem("USER", JSON.stringify(user));
          // router.push({ name: "information" });
          const role = user.role[0];
          if (role) {
            if (role == "Admin") {
              router.push({ name: "threats" });
              return;
            } else if (role == "IT-Security Consultant") {
              router.push({ name: "kunden" });
              return;
            }
            else{
              router.push({ name: "customerThreat" });
            }
          }

        })
        .catch((err)=>{
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [GET_GREETING](context, data) {
      axios
        .post(API_URL + "/greeting", {
          email: data.email,
        })
        .then((response) => {
          context.commit(SET_GREETING, response);
        });
    },
    [GET_EMAILADDRESS](context, data) {
      axios
        .post(API_URL + "/get-email-address", {
          id: data.id,
        })
        .then((response) => {
          context.commit(SET_EMAIL_ADDRESS, response);
        })
        .catch((err)=>{
          console.log(err);
          
        })
    },
  },
  mutations: {
    async [SET_INTRO_DROPDOWN](state, data) {
      state.roles = data.roles;
      state.legal_roles = data.legal_roles;
      const language = await localStorage.getItem('LANGUAGE')
      state.industries = data.industries.items.map((industry) => {
        return {
          id: industry.id,
          name: industry.friendlyTranslations[`${language}.title`].value,
        };
      });
    },

    async [SET_INTRO_INVITE_DETAILS](state, data) {
      if(null==data) return;
      state.intro.profile.first_name = data.first_name;
      state.intro.profile.last_name = data.last_name;
      state.intro.profile.title = data.salutation;
      state.intro.company.company_website = data.website;
      state.intro.company.company_name = data.company_name;

    },

    [SET_GREETING](state, response) {
      state.greeting = response.data.greeting;
    },
    [SET_EMAIL_ADDRESS](state, response) {
      state.email = response.data.email;
    },
  },
};
