import router from "@/router";
import axios from "axios";
import {
  ENABLE_EMAIL_2FA,
  GET_GOOGLE_CODE,
  VERIFY_EMAIL_CODE,
  VERIFY_GOOGLE_CODE,
  DISABLE_2FA
} from "./actions.type";
import { SET_CODE } from "./mutations.type";
import API_URL from "../../common/config";

export const settings_module = {
  state: {
    code: null
  },
  actions: {
    [GET_GOOGLE_CODE](context) {
      axios.post(API_URL + "/register/google2fa").then((response) => {
        context.commit(SET_CODE, response.data.data);
      });
    },
    [VERIFY_GOOGLE_CODE](context, data) {
      return axios
        .post(API_URL + "/validate_google_secret", data)
    },
    [ENABLE_EMAIL_2FA](context) {
      axios.post(API_URL + "/enable_email_2fa").then((response) => {
        console.log(response);
      });
    },
    [VERIFY_EMAIL_CODE](context, emailVerification_code) {
      axios
        .post(API_URL + "/validate_email_secret", {
          emailVerification_code: emailVerification_code,
        })
        .then((response) => {
          console.log(response);
          
          localStorage.setItem("EMAIL2FA", "0");
          // localStorage.getItem('ROLE')=='Admin' ? router.push({ name: 'threats' }) : router.push({ name:'dashboard' })
        });
    },
    
    [DISABLE_2FA](context,auth_type) {
      return axios
        .post(API_URL + "/disable_2fa", {
          type: auth_type,
        })
        
    },
  },
  mutations: {
    [SET_CODE](state, response) {
      state.code = response;
    },
  },
};
