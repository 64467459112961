<template>
   <input type="file" @change="importTest"  />
</template>

<script >
import { defineComponent } from 'vue'
import store from '@/store';
import { IMPORT_FILE } from '@/store/modules/actions.type';

export default defineComponent({
    setup() {
        function importTest(event){
            const file = event.target.files[0];
            store.dispatch(IMPORT_FILE,file)   
        }
        return {
            importTest
        }
    },
})
</script>
