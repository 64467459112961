import axios from "axios";
import {
  DELETE_COMPANY_ASSET,
  GET_COMPANY_ASSET,
  GET_MENU,
  SAVE_COMPANY_ASSET,
  SEND_ASSET_MAIL,
} from "./actions.type";
import API_URL from "@/common/config.js";
import {
  SET_COMPANY_ASSET,
  SET_SEND_ASSETMAIL,
  SET_SEND_ASSETMAILERROR,
} from "./mutations.type";
import popupService from "../../service/popup.service";
import store from "../../store";
import { useStore } from "vuex";
const { onBackendError, onSuccessMessage } = popupService();
const user = localStorage.getItem("USER") ?? "4";
        const id = JSON.parse(user);
export const company_asset_module = {
  state: {
    companyAsset: [],
    assetDropdown: [],
    language: localStorage.getItem("LANGUAGE"),
  },
  actions: {
    [GET_COMPANY_ASSET](context) {
      
      axios.get(API_URL + "/customer/companyAssets").then((response) => {
        context.commit(SET_COMPANY_ASSET, response.data.data);
      store.dispatch(GET_MENU,id.id)
      });
    },
    [SAVE_COMPANY_ASSET](context, data) {
      axios
        .post(API_URL + "/customer/companyAssets", { companyAssets: data })
        .then((response) => {
          context.commit(SET_COMPANY_ASSET, response.data.data);
          store.dispatch(GET_MENU,id.id)      
        })
        .catch((err)=>{
          context.commit(SET_COMPANY_ASSET, []);

        })
    },
    [DELETE_COMPANY_ASSET](context, { id, index }) {
      axios.delete(API_URL + "/customer/companyAssets/" + id).then(() => {
        store.dispatch(GET_MENU,id.id);
        context.commit(DELETE_COMPANY_ASSET, index);
      });
    },
    [SEND_ASSET_MAIL](context, data) {
      axios
        .post(API_URL + "/customer/send/asset/mail", {
          asset: data.asset,
        })
        .then((response) => {
          context.commit(SET_SEND_ASSETMAIL, response);
        })
        .catch((error) => {
          context.commit(SET_SEND_ASSETMAILERROR, error);
        });
    },
  },
  mutations: {
    [SET_COMPANY_ASSET](state, data) {
      state.companyAsset = data.companyAssets;
      state.assetDropdown = data.assets.items.map((asset) => {
        return {
          id: asset["id"],
          name: asset["friendlyTranslations"][state.language + ".title"][
            "value"
          ],
        };
      });
    },
    [DELETE_COMPANY_ASSET](state, index) {
      state.companyAsset.splice(index, 1);
    },
    [SET_SEND_ASSETMAILERROR](state, error) {
      if (error.response) {
        state.error = error.response.data.errors;
      }
      onBackendError(error);
    },
    [SET_SEND_ASSETMAIL](state, res) {
      state.error = [];
      state.success = res.data;
      onSuccessMessage(res);
    },
  },
};
