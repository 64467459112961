import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRoute,
} from "vue-router";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Reset from "../views/Reset.vue";
import ResetPassword from "../views/ResetPassword.vue";
import test from "../views/test.vue";
import store from "@/store";

const securious = function () {
  const url = window.location.href;
  if (!url.includes("78.46.206.99") && !url.includes("securious.de")) {
    return false;
  }
  return true;
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    // meta: {
    //   title:  securious() ? 'Securious' : 'CyberXperts'
    // }
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/sales-login",
    name: "sales.login",
    component: () => import("@/views/SalesLogin.vue"),
  },
  {
    path: "/sales-invite",
    name: "sales.invite",
    component: () => import("@/views/SalesInvite.vue")
  },
  {
    path: "/register/:slug?",
    name: "Register",
    component: Register,
    props: true,
  },
  {
      path: "/consultant/register",
      name: "ConsultantRegister",
      component: Register,
      props: { type : 'consultant'},
  },
  //  we're using same register for sales registration as well now
  // {
  //   path: "/sales-register/:slug?",
  //   name: "sales.register",
  //   component: () => import('@/views/RegisterSales.vue'),
  //   props: true,
  // },
  {
    path: "/password/reset",
    name: "passwordReset",
    component: Reset,
  },
  {
    path: "/reset",
    name: "resetPassword",
    component: ResetPassword,
    props: true,
  },
  {
    path: "/verify",
    name: "verify",
    component: () => import("@/views/Verify.vue"),
  },
  {
    path: "/email2fa",
    name: "email2fa",
    component: () => import("@/views/2FA/Email2FA.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Customer/Dashboard/Index.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },
  {
    path: "/threats",
    name: "threats",
    component: () => import("@/views/Threats/Threat/Threats.vue"),
  },
  {
    path: "/threat/create",
    name: "createThreats",
    component: () =>
      import("@/views/Threats/Threat/ThreatCreate/ThreatsCreate.vue"),
  },
  {
    path: "/threat/update/:id",
    name: "threatUpdate",
    component: () =>
      import("@/views/Threats/Threat/ThreatUpdate/ThreatUpdate.vue"),
    props: true,
  },
  {
    path: "/threat/question/edit/:id",
    name: "editThreatQuestion",
    component: () => import("@/views/Threats/Analysis/QuestionEdit/Index.vue"),
  },
  {
    path: "/threat/question/create/:id",
    name: "createThreatQuestion",
    component: () =>
      import("@/views/Threats/Analysis/QuestionCreate/QuestionCreate.vue"),
  },
  {
    path: "/threat/recommendation/edit/:id",
    name: "editThreatRecommendation",
    props: true,
    component: () =>
      import("@/views/Threats/Recommendation/RecommendationUpdate/Edit.vue"),
  },
  {
    path: "/threat/recommendation/create/:id/:question?",
    name: "createThreatRecommendation",
    component: () =>
      import("@/views/Threats/Recommendation/RecommendationCreate/Create.vue"),
    props: true
  },
  {
    path: "/industries",
    name: "industries",
    component: () => import("@/views/Industries.vue"),
  },
  {
    path: "/solution-partners",
    name: "solutionpartners",
    component: () => import("@/views/SolutionPartners/Index.vue"),
  },
  {
    path: "/solution-partners/create",
    name: "createSolutionpartners",
    component: () => import("@/views/SolutionPartners/Create.vue"),
  },
  {
    path: "/solution-partners/edit/:id",
    name: "editSolutionpartners",
    component: () => import("@/views/SolutionPartners/Edit.vue"),
    props: true,
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/News/News.vue"),
  },
  {
    path: "/news/create",
    name: "newsCreate",
    component: () => import("@/views/News/NewsCreate.vue"),
  },
  {
    path: "/news/update/:id",
    name: "newsUpdate",
    component: () => import("@/views/News/NewsUpdate.vue"),
    props: true,
  },
  {
    path: "/news/show/:id",
    name: "viewNewsDetail",
    props: true,
    component: () => import("@/views/Customer/Dashboard/LatestNews/Detail.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings/Index.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/UserList.vue"),
  },
  {
    path: "/companies",
    name: "companies",
    component: () => import("@/views/CompanyList.vue"),
  },
  {
    path: "/consultants",
    name: "consultants",
    component: () => import("@/views/ConsultantList.vue"),
  },
  {
    path: "/users/change-password/:id",
    name: "changePassword",
    props: true,
    component: () => import("@/views/ChangePassword.vue"),
  },
  {
    path: "/recommendations",
    name: "recommendations",
    component: () => import("@/views/Customer/Recommendations/Index.vue"),
  },
  {
    path: "/certificates",
    name: "certificates",
    component: () => import("@/views/Certificate/Index.vue"),
  },
  {
    path: "/kunden",
    name: "kunden",
    component: () => import("@/views/Consultant/Kunden.vue"),
  },
  {
    path: "/email2fa",
    name: "email2fa",
    component: () => import("@/views/2FA/Email2FA.vue"),
  },
  {
    path: "/google2fa",
    name: "google2fa",
    component: () => import("@/views/2FA/Google2FA.vue"),
  },
  {
    path: "/data-leak",
    name: "data-leak",
    component: () => import("@/views/Customer/DataLeaks/DataLeaks.vue"),
  },
  // {
  //   path: "/introduction",
  //   name: "introduction",
  //   component: () => import("@/views/Intro.vue"),
  // },
  // {
  //   path: "/information",
  //   name: "information",
  //   component: () => import("@/views/CustomerInfo.vue"),
  // },
  {
    path: "/email2fa",
    name: "email2fa",
    component: () => import("@/views/2FA/Email2FA.vue"),
  },
  {
    path: "/google2fa",
    name: "google2fa",
    component: () => import("@/views/2FA/Google2FA.vue"),
  },
  {
    path: "/data-leak",
    name: "data-leak",
    component: () => import("@/views/Customer/DataLeaks/DataLeaks.vue"),
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import("@/views/Intro.vue"),
  },
  {
    path: "/company-assets",
    name: "company-assets",
    component: () => import("@/views/Customer/CompanyAssets/Index.vue"),
  },
  {
    path: "/threat",
    name: "customerThreat",
    component: () => import("@/views/Customer/Threats/Library.vue"),
  },
  {
    path: "/threat/:id",
    name: "preventThreat",
    component: () => import("@/views/Customer/Threats/RiskAnalysis/Index.vue"),
    props: true,
  },
  {
    path: "/setting",
    name: "customerSetting",
    component: () => import("@/views/Customer/Setting.vue"),
  },
  {
    path: "/threat/:id/recommendations",
    name: "threatRecommendation",
    component: () =>
      import("@/views/Customer/Threats/RiskAnalysis/Recommendation/Index.vue"),
    props: true,
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    component: test,
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: test,
  },
  {
    path: "/activity-log",
    name: "activityLog",
    component: () => import("@/views/Logs.vue"),
  },
  {
    path: "/find-consultant",
    name: "findConsultant",
    component: () => import("@/views/Customer/FindConsultant.vue"),
  },
  {
    path: "/threats/import-export",
    name: "importExportThreat",
    component: () => import("@/views/Threats/Threat/ImportExport.vue"),
  },
  {
    path: "/consultant/threats/templates",
    name: "consultant.threat.template",
    component: () => import("@/views/Consultant/Template/Index.vue"),
  },
  {
    path: "/consultant/threats/edit",
    name: "consultant.threat.edit",
    component: () => import("@/views/Consultant/Edit/Index.vue"),
  },
  {
    path: "/consultant/template/:id",
    name: "consultant.view.template",
    component: () => import("@/views/Consultant/Components/ThreatView.vue"),
    props: true,
  },
  {
    path: "/consultant/threat/edit/:id",
    name: "consultant.view.edit",
    component: () => import("@/views/Consultant/Components/ThreatView.vue"),
    props: true,
  },
  {
    path: "/consultant/threat/create",
    name: "consultant.threat.create",
    component: () =>
      import("@/views/Consultant/Edit/ThreatCreate.vue"),
  },
  {
    path: "/consultant/question/edit/:id",
    name: "consultant.question.edit",
    component: () =>
      import("@/views/Consultant/Question/QuestionEdit/EditQuestion.vue"),
  },
  {
    path: "/consultant/question/create/:id",
    name: "consultant.question.create",
    component: () =>
      import("@/views/Consultant/Question/QuestionCreate/CreateQuestion.vue"),
    props: true
  },
  {
    path: "/consultant/recommendation/create/recommendation/create/:id",
    name: "consultant.recommendation.create",
    component: () =>
      import("@/views/Consultant/Recommendation/Create.vue"),
  },
  {
    path: "/consultant/recommendation/edit/:id",
    name: "consultant.recommendation.edit",
    component: () =>
      import("@/views/Consultant/Recommendation/Update.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
