import axios from "axios";
import API_URL from "@/common/config.js";
import {
    GET_DASHBOARD_ITEM,
    GET_CUSTOMER_SHOW_THREAT,
    GET_CUSTOMER_THREAT,
    START_ANALYSIS,
    STORE_CUSTOMER_ANSWER,
    GET_CUSTOMER_RECOMMENDATION,
    RESTART_THREAT_ANALYSIS,
    GET_CUSTOMER_RECOMMENDATION_LIST,
    GET_CUSTOMER_SETTING,
    UPDATE_COMPANY_SETTING,
    GET_BILLING_PORTAL,
    GET_BILLING_DETAILS,
    INVITE_USER,
    GET_RECOMMENDATION_COUNT,
    CHANGE_RECOMMENDATION_STATUS,
    DELETE_COMPANY_ASSET,
    FIND_CONSULTANT,
    DELETE_CUSTOMER_PROFILE_IMAGE,
    DELETE_CUSTOMER_COMPANY_IMAGE
} from "./actions.type";
import {
    SET_DASHBOARD_ITEM,
    SET_CUSTOMER_SHOW_THREAT,
    SET_CUSTOMER_THREATS,
    SET_CUSTOMER_RECOMMENDATION,
    SET_RECOMMENDATION_COUNT,
    SET_CUSTOMER_RECOMMENDATION_LIST,
    SET_CUSTOMER_SETTING,
    SET_TEAM_MEMBERS,
    DELETED_CUSTOMER_PROFILE_IMAGE,
    DELETED_CUSTOMER_COMPANY_IMAGE,
    SET_PROFILE_IMAGE,
    SET_COMPANY_IMAGE
} from "./mutations.type";
import router from "@/router";
import {notify} from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import popupService from "../../service/popup.service";
import {Recommendation} from "@/models/customer/Recommendation";
import {Threat} from "@/models/customer/Threat";
import {IThreat} from "@/Interfaces/customer/IThreat";

const {onBackendError, onSuccessMessage} = popupService();

export const customer_threat_module = {
    state: {
        analyseThreat: [],
        recommendationThreat: new Threat(),
        nextThreat: [],
        recommendation: [],
        customerQA: [],
        customerThreat: [],
        neutralizedThreats: [],
        toReAnalyzedThreats: [],
        threatIntialCount: 0,
        threatsNeutralizedCount: 0,
        threatsCurrentCount: 0,
        lastIndex: 0,
        recommendationCount: {},
        customerRecommendation: [],
        dashboardThreat: [],
        allrecommendations: [],
        analyzedThreats: [],
        securityScores: [],
        totalScores: [],
        graphLabel: [],
        news: [],
        industry: [],
        companySetting: [],
        roles: [],
        updatePlanUrl: "",
        billingDetails: [],
        teamMembers: [],
        language: localStorage.getItem("LANGUAGE"),
        inviteUser: {
            salutation: "Herr",
            first_name: "",
            last_name: "",
            access_level: "read_only",
            email: "",
        },
    },
    actions: {
        [DELETE_CUSTOMER_PROFILE_IMAGE]({state, commit}, data) {
            axios
                .post(API_URL + "/customer/profile/deleteImage/" + data)
                .then((response) => {
                    commit(DELETED_CUSTOMER_PROFILE_IMAGE);
                }).catch((err) => {
                console.error(err);
            })
        },
        [DELETE_CUSTOMER_COMPANY_IMAGE]({state, commit}, data) {
            axios
                .post(API_URL + "/customer/company/deleteImage/" + data)
                .then((response) => {
                    commit(DELETED_CUSTOMER_COMPANY_IMAGE);
                }).catch((err) => {
                console.error(err);
            })
        },
        [GET_CUSTOMER_THREAT](context) {
            axios.get(API_URL + "/customer/threat").then((response) => {
                context.commit(SET_CUSTOMER_THREATS, response);
            })
                .catch((err) => {
                    console.log(err);
                })

        },
        [GET_CUSTOMER_RECOMMENDATION](context, id) {
            axios
                .get(API_URL + "/customer/recommendation", {
                    params: {
                        id: id,
                    },
                })
                .then((response) => {
                    context.commit(SET_CUSTOMER_RECOMMENDATION, response.data.data);
                })
                .catch((response) => {
                    context.commit(SET_CUSTOMER_RECOMMENDATION, []);
                });
        },
        async [GET_CUSTOMER_SHOW_THREAT](context, id) {
            await axios.get(API_URL + "/customer/threat/" + id).then((response) => {
                context.commit(SET_CUSTOMER_SHOW_THREAT, response);
            })
                .catch((err) => {
                    console.log(err);
                })
        },
        [START_ANALYSIS](context, id) {
            axios.post(API_URL + "/customer/threat-analysis/started", {
                threat_id: id,
            });
        },
        [STORE_CUSTOMER_ANSWER](
            context,
            {data, isCompleted, nextQuestions, threatId}
        ) {
            axios
                .post(API_URL + "/customer/threat", {
                    data: data,
                    is_completed: isCompleted,
                    threat_id: threatId,
                    next_questions: nextQuestions,
                })
                .then(() => {
                    if (isCompleted) router.push({name: "threatRecommendation"});
                }).catch((err) => {
                console.log(err);
            })
        },
        [RESTART_THREAT_ANALYSIS](constext, id) {
            axios
                .post(API_URL + "/customer/threat/restartAnalysis/" + id)
                .then(() => {
                    router.push({name: "preventThreat", params: {id: id}});
                }).catch((err) => {
                console.log(err);
            })
        },
        async [GET_RECOMMENDATION_COUNT](context) {
            await axios
                .get(API_URL + "/customer/recommendation/getRecommendationCounts")
                .then((response) => {
                    context.commit(SET_RECOMMENDATION_COUNT, response.data.data);
                }).catch((err) => {
                    console.log(err);
                })
        },
        async [GET_CUSTOMER_RECOMMENDATION_LIST](context, status) {
            await axios
                .get(API_URL + "/customer/recommendation/getRecommendations", {
                    params: {status: status},
                })
                .then((response) => {
                    context.commit(SET_CUSTOMER_RECOMMENDATION_LIST, response.data.data);
                }).catch((err) => {
                    console.log(err);
                })
        },
        [GET_DASHBOARD_ITEM](context) {
            axios.get(API_URL + "/customer/dashboard").then(async (response) => {
                console.log(response);
                await context.commit(SET_DASHBOARD_ITEM, response.data.data);
                context.commit(SET_PROFILE_IMAGE, response.data.data.customerImage ?? false)
                context.commit(SET_COMPANY_IMAGE, response.data.data.companyImage ?? false)
            }).catch((err) => {
                console.log(err);
            })
        },
        [GET_CUSTOMER_SETTING](context) {
            axios.get(API_URL + "/customer/user").then(async (response) => {
                await context.commit(SET_CUSTOMER_SETTING, response.data.data);
            }).catch((err) => {
                console.log(err);
            })
        },
        [UPDATE_COMPANY_SETTING]({state}) {
            axios
                .post(API_URL + "/customer/user", state.companySetting).then(() => {
                Swal.fire({
                    toast: true,
                    showConfirmButton: false,
                    position: "top-end",
                    icon: "success",
                    title: "Angaben gespeichert",

                });
            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    toast: true,
                    showConfirmButton: false,
                    position: "top-end",
                    icon: "error",
                    title: "Etwas ist schief gelaufen",
                });
            })
        },
        [GET_BILLING_PORTAL]({state}) {
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "* ";
            axios.get(API_URL + "/billing-portal").then((response) => {
                state.updatePlanUrl = response.data;
            }).catch((err) => {
                console.log(err);

            })
        },
        [GET_BILLING_DETAILS]({state}) {
            axios.get(API_URL + "/customer/billing-details").then((response) => {
                state.billingDetails = response.data.data;
            }).catch((err) => {
                console.log(err);

            })
        },
        DELETE_INVITE({state}, payload) {
            return axios.get(API_URL + "/consultant/delete-invites/" + payload)
                .then((res) => {
                    Swal.fire({
                        toast: true,
                        showConfirmButton: false,
                        position: "top-end",
                        icon: "success",
                        title: "Invitation is deleted",
                    });
                })
                .catch((response) => {
                    Swal.fire({
                        toast: true,
                        showConfirmButton: false,
                        position: "top-end",
                        icon: "error",
                        title: "Etwas ist schief gelaufen ",
                    });
                });
        },
        [INVITE_USER]({state}) {
            axios
                .post(API_URL + "/consultant/invite", state.inviteUser)
                .then((response) => {
                    Swal.fire({
                        toast: true,
                        showConfirmButton: false,
                        position: "top-end",
                        icon: "success",
                        title: response.data.message,
                    });
                })
                .catch((response) => {
                    Swal.fire({
                        toast: true,
                        showConfirmButton: false,
                        position: "top-end",
                        icon: "error",
                        title: "Etwas ist schief gegangen ",
                    });
                });
        },

        [CHANGE_RECOMMENDATION_STATUS](context, data) {
            return axios
                .post(API_URL + "/customer/recommendation/changeStatus", {
                    recommendation_id: data.recommendation_id,
                    status: data.status,
                    threat_id: data.threat_id,
                    solution_partner_products_id: null,
                    customer_answer: data.customer_answer,
                })
                .then((response) => {
                    return response.data;
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        [FIND_CONSULTANT](context, msg) {
            axios
                .post(API_URL + "/customer/find-consultant", {message: msg})
                .then(() => {
                    notify({
                        title: "Berater benachrichtigt",
                        type: "success",
                    });
                })
                .catch(() => {
                    notify({
                        title: "Etwas ist schief gelaufen",
                        type: "fail",
                    });
                });
        },
    },
    mutations: {
        [DELETED_CUSTOMER_PROFILE_IMAGE](state, response) {
            state.companySetting.profile.image = "";
        },
        [DELETED_CUSTOMER_COMPANY_IMAGE](state, response) {
            state.companySetting.company.image = "";
        },
        [SET_CUSTOMER_THREATS](state, response) {
            console.log(response.data.data.items);
            state.customerThreat = response.data.data.threats ?? response.data.data.items;
            state.neutralizedThreats = response.data.data.neutralizedThreats;
            state.toReAnalyzedThreats = response.data.data.toReAnalyzedThreats;
            state.threatsNeutralizedCount =
                response.data.data.threatsNeutralizedCount;
            state.threatIntialCount = response.data.data.threatIntialCount;
            state.threatsCurrentCount = state.customerThreat?.length;
        },
        [SET_CUSTOMER_SHOW_THREAT](state, response) {
            state.analyseThreat = response.data.data;
            state.lastIndex = response.data.data.last_answered_question;
        },
        [SET_CUSTOMER_RECOMMENDATION](state, data) {
            const initializer = data.threat;
            initializer.attemptedQuestionAnswers = data.attemptedQaByUser;
            state.recommendationThreat = new Threat(initializer);
            state.nextThreat = data.nextThreat;
            state.customerQA = data.attemptedQaByUser;
        },
        [SET_RECOMMENDATION_COUNT](state, data) {
            state.recommendationCount = data;
        },
        [SET_CUSTOMER_RECOMMENDATION_LIST](state, data) {
            state.customerRecommendation = data;
        },
        [SET_DASHBOARD_ITEM](state, data) {
            state.dashboardThreat = data.threats;
            state.allrecommendations = data.allrecommendations;
            state.analyzedThreats = data.analyzedThreats;
            state.securityScores = data.securityScores;
            state.totalScores = data.totalScores;
            state.graphLabel = data.graphLabel;
            state.news = data.news ? data.news.items : [];
            // localStorage.setItem("CustomerImage", data.customerImage ?? null);
            // localStorage.setItem("CompanyImage", data.companyImage ?? null);
        },
        [SET_CUSTOMER_SETTING](state, data) {
            state.companySetting = data.profile;
            state.roles = data.roles;
            // console.log('indusctries', data.industries);
            const language = localStorage.getItem("LANGUAGE");
            state.industries = data.industries.items.map((industry) => {
                return {
                    id: industry["id"],
                    name: industry["friendlyTranslations"][language + ".title"][
                        "value"
                        ],
                };
            });
        },
    },
};
