import axios from 'axios'
import API_URL from "@/common/config.js";
import { GET_CERTIFICATE_DATA } from './actions.type'
import { SET_CERTIFICATE_DATA } from './mutations.type';

export const certificate_module = {
    state: {
        certificate: [],
    },
    actions: {
        [GET_CERTIFICATE_DATA] (context) {
            axios.get(API_URL+'/customer/certificate').then((response) => {
                context.commit(SET_CERTIFICATE_DATA,response.data.data)
            })
            .catch(()=>{
                context.commit(SET_CERTIFICATE_DATA,[]);
            })
        },
    },
    mutations: {
        [SET_CERTIFICATE_DATA] (state,data) {
            state.certificate = data
        }
    }
}