import axios from "axios";
import {
  DELETE_ASSET,
  DELETE_ASSET_IMAGE,
  DELETE_ASSET_PRODUCT,
  DELETE_PRODUCT_IMAGE,
  EDIT_SOLUTION_PARTNER,
  GET_ASSET_DROPDOWN,
  GET_CONSULTANT_SOLUTION_PARTNER_PRODUCT,
  GET_SOLUTION_PARTNER,
  GET_SOLUTION_PARTNER_PRODUCT,
  STORE_ASSET,
  TOGGLE_ASSET_STATUS,
  UPDATE_ASSET,
} from "./actions.type";
import {
  ADD_ASSET_ALERT,
  ADD_EDIT_SOLUTION_PARTNER,
  ADD_PRODUCT_LIST,
  DELETE_ASSET_ALERT,
  DELETE_EDIT_PRODUCT_LIST,
  DELETE_PRODUCT_LIST,
  RESET_ASSET,
  SET_ASSET_DROPDOWN,
  SET_EDIT_SOLUTION_PARTNER,
  SET_SOLUTION_PARTNER,
  SET_SOLUTION_PARTNER_PRODUCT,
} from "./mutations.type";
import API_URL from "../../common/config";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";

export const solutionPartner_module = {
  state: {
    solutionPartner: {
      image: "",
      status: "",
      friendlyTranslations: {
        EN: {
          title: "",
          description: "",
        },
        DE: {
          title: "",
          description: "",
        },
      },
      solution_partner_products: [],
    },
    solutionPartners: [],
    editSolutionPartner: [],
    pagination: [],
    getIndustries: [],
    getAssetMappings: [],
    solutionPartnerProducts: [],
  },
  actions: {
    async [GET_SOLUTION_PARTNER](
      context,
      { per_page, search = "", status = "", category = null }
    ) {
      await axios
        .get(API_URL + "/admin/solution_partners", {
          params: {
            per_page: per_page,
            search: search,
            status: status,
            category: category,
          },
        })
        .then((response) => {
          context.commit(SET_SOLUTION_PARTNER, response.data.data);
        })
        .catch((err)=>{
          context.commit(SET_SOLUTION_PARTNER, []);

        })
    },
    [STORE_ASSET]({ state, commit }, status) {
      state.solutionPartner.status = status;
      axios
        .post(API_URL + "/admin/solution_partners", state.solutionPartner)
        .then(() => {
          commit(RESET_ASSET);
          router.push({ name: "solutionpartners" });
          notify({
            title: " Lösungspartner erstellt",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [UPDATE_ASSET]({ state }, status) {
      state.editSolutionPartner.status = status;
      axios
        .put(
          API_URL + "/admin/solution_partners/" + state.editSolutionPartner.id,
          state.editSolutionPartner
        )
        .then((response) => {
          notify({
            title: " Lösungspartner aktualisiert ",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    async [EDIT_SOLUTION_PARTNER](context, id) {
      await axios
        .get(API_URL + "/admin/solution_partners/" + id + "/edit")
        .then((response) => {
          context.commit(SET_EDIT_SOLUTION_PARTNER, response.data.data);
        });
    },
    [TOGGLE_ASSET_STATUS](context, { id, is_active }) {
      axios
        .post(API_URL + "/admin/solution_partners/toggleStatus", {
          id,
          is_active,
        })
        .then(() => {
          notify({
            title: " Lösungspartner erfolgreich aktualisiert ",
            type: "success",
            text: is_active ? "Aktiviert" : "Deaktiviert",
          });
        })
        .catch(() => {
          notify({
            title: "Aktualisierung des Lösungspartners fehlgeschlagen ",
            type: "error",
          });
        });
    },
    [DELETE_ASSET](context, id) {
      axios.delete(API_URL + "/admin/solution_partners/" + id).then(() => {
        notify({
          title: "Lösungspartner gelöscht",
          type: "success",
        });
      })
      .catch(() => {
        notify({
          title: "Etwas ist schief gelaufen",
          type: "fail",
        });
      })
    },
    async [DELETE_ASSET_IMAGE]({ state }) {
      await axios.post(
        API_URL +
          "/admin/solution_partners/deleteImage/" +
          state.editSolutionPartner.id
      );
    },
    [DELETE_PRODUCT_IMAGE]({ state }, { id, index }) {
      axios.post(
        API_URL + "/admin/solution_partner_products/deleteImage/" + id
      );
    },
    [DELETE_ASSET_PRODUCT](context, id) {
      axios
        .delete(API_URL + "/admin/solution_partner_products/" + id)
        .then(() => {
          notify({
            title: " Lösungspartnerprodukt gelöscht ",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [GET_ASSET_DROPDOWN](context, language) {
      axios
        .get(API_URL + "/admin/solution_partners/create")
        .then((response) => {
          context.commit(SET_ASSET_DROPDOWN, { response, language });
        });
    },
    [DELETE_ASSET_ALERT](context, id) {
      axios.post(
        API_URL + "/admin/solution_partner_products/deleteAssetAlert/" + id
      );
    },
    [GET_SOLUTION_PARTNER_PRODUCT](context) {
      axios
        .get(API_URL + "/admin/solution_partner_products")
        .then((response) => {
          context.commit(SET_SOLUTION_PARTNER_PRODUCT, response.data.data);
        });
    },
    [GET_CONSULTANT_SOLUTION_PARTNER_PRODUCT](context) {
      axios
        .get(API_URL + "/consultant/solution-partner")
        .then((response) => {
          context.commit(SET_SOLUTION_PARTNER_PRODUCT, response.data.data);
        });
    },
  },
  mutations: {
    [ADD_PRODUCT_LIST](state, data) {
      state.solutionPartner.solution_partner_products.push(data);
    },
    [ADD_EDIT_SOLUTION_PARTNER](state, data) {
      state.editSolutionPartner.solutionPartnersProducts.push(data);
    },
    [DELETE_PRODUCT_LIST](state, index) {
      state.solutionPartner.solution_partner_products.splice(index, 1);
    },
    [DELETE_EDIT_PRODUCT_LIST](state, index) {
      state.editSolutionPartner.solutionPartnersProducts.splice(index, 1);
    },
    [SET_SOLUTION_PARTNER_PRODUCT](state, data) {
      state.solutionPartnerProducts = data;
    },
    [ADD_ASSET_ALERT](state, { asset, index, edit = null }) {
      if (edit)
        state.editSolutionPartner.solutionPartnersProducts[
          index
        ].asset_alert.push(asset);
      else
        state.solutionPartner.solution_partner_products[index].asset_alert.push(
          asset
        );
    },
    [DELETE_ASSET_ALERT](state, { index, ind, edit = null }) {
      if (edit)
        state.editSolutionPartner.solutionPartnersProducts[
          index
        ].asset_alert.splice(ind, 1);
      else
        state.solutionPartner.solution_partner_products[
          index
        ].asset_alert.splice(ind, 1);
    },
    [SET_SOLUTION_PARTNER](state, data) {
      state.solutionPartners = data.items;
      state.pagination = data.pagination;
    },
    [SET_EDIT_SOLUTION_PARTNER](state, data) {
      state.editSolutionPartner = data;
    },
    [RESET_ASSET](state) {
      state.solutionPartner = {
        image: "",
        status: "",
        solution_partner_products : [],
        friendlyTranslations: {
          EN: {
            title: "",
            description: "",
          },
          DE: {
            title: "",
            description: "",
          },
        },
      };
    },
    [SET_ASSET_DROPDOWN](state, { response, language }) {
      state.getIndustries = response.data.data.industries.items;
      state.getAssetMappings = response.data.data.assetMappings;
      state.getIndustries = state.getIndustries.map((industry) => {
        return {
          id: industry["id"],
          name: industry["friendlyTranslations"][language + ".title"]["value"],
        };
      });
    },
  },
};
