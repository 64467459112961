
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    isSecurious: {
      type: Boolean,
      default: true,
      required: false
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    
    styless: {
      type: String,
      required: false,
      default: ''
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    }
  },
});
