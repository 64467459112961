export const SET_AUTH = "setUser";
export const SET_VERIFICATION = "setVerification";
export const PURGE_AUTH = "logOut";
export const SET_MENU = "menu";
export const SET_INDUSTRIES = "setIndustries";
export const ADD_INDUSTRIES = "addIndustries";
export const REMOVE_INDUSTRY = "removeIndustry";
export const SAVE_INDUSTRY = "saveIndustry";
export const SAVE_NEWS = "saveNews";
export const SET_NEWS = "setNews";
export const EDIT_NEWS = "editNews";
export const UPDATE_NEWS = "updateNews";
export const DELETE_NEWS = "deleteNews";
export const RESET_NEWS = "resetNews";
export const SET_CODE = "setCode";
export const ADD_PRODUCT_LIST = "addProductList";
export const DELETE_PRODUCT_LIST = "deleteProductList";
export const DELETE_EDIT_PRODUCT_LIST = "deleteEditProductList";
export const ADD_ASSET_ALERT = "addAssetAlert";
export const DELETE_ASSET_ALERT = "deleteAssetAlert";
export const RESET_ASSET = "resetAsset";
export const SET_SOLUTION_PARTNER = "setSolutionPartner";
export const SET_THREATS = "setThreats";
export const SET_EDIT_THREAT = "setEditThreat";
export const SET_THREAT_DROPDOWN = "setThreatDropdown";
export const ADD_ANSWER = "addAnswer";
export const QUESTION_DELETED = "questionDeleted";
export const DELETE_ANSWER = "deleteAnswer";
export const ADD_EDIT_ANSWER = "addEditAnswer";
export const SET_QUESTION_LIST = "setQuestionList";
export const RESET_QUESTION = "resetQuestionList";
export const SET_ANSWER_LIST = "setAnswerList";
export const SET_RECOMMENDATION_LIST = "setRecommendationList";
export const SET_EDIT_QUESTION = "setEditQuestion";
export const GOTO_EDIT_QUESTION = "gotoEditQuestion";
export const SET_QUESTION_CONDITION = "setQuestionCondition";
export const SET_EDIT_SOLUTION_PARTNER = "setEditSolutionPartner";
export const ADD_EDIT_SOLUTION_PARTNER = "addEditSolutionPartner";
export const SET_ASSET_DROPDOWN = "setAssetDropdown";
export const SET_LOGINERROR = "setLoginError";
export const SET_REGISTERERROR = "setRegisterError";
export const CAN_ADD = "canAdd";
export const SET_INTRO_DROPDOWN = "setIntroDropdown";
export const SET_INTRO_INVITE_DETAILS = "setIntroInviteDetails";
export const CLEAR_INTRODUCTION = "clearIntroduction";
export const SET_CUSTOMER_THREATS = "setCustomerThreats";
export const SET_CUSTOMER_SHOW_THREAT = "setCustomerShowThreat";
export const SET_RECOMMENDATION_DROPDOWN = "setRecommendationDropdown";
export const SET_RECOMMENDATION_ANSWER = "setRecommendationAnswer";
export const SET_EDIT_RECOMMENDATION_ANSWERS = "setEditRecommendationAnswers";
export const SET_CUSTOMER_RECOMMENDATION = "setCustomerRecommendation";
export const SET_COMPANY_ASSET = "setCompanyAsset";
export const DELETE_COMPANY_ASSET = "deleteCompanyAsset";
export const SET_CERTIFICATE_DATA = "setCertificateData";
export const SET_CUSTOMER_RECOMMENDATION_LIST = "setCustomerRecommendationList";
export const SET_RECOMMENDATION_COUNT = "setRecommendationCount";
export const SET_DASHBOARD_ITEM = "setDashboardItem";
export const SET_CUSTOMER_SETTING = "setCustomerSetting";
export const SET_BILLING_DETAILS = "setBillingDetails";
export const SET_TEAM_MEMBERS = "setTeamMembers";
export const SET_SOLUTION_PARTNER_PRODUCT = "setSolutionPartnerProduct";
export const SET_EDIT_RECOMMENDATION = "setEditRecommendation";
export const SET_VERIFYERROR = "setVerifyError";
export const SET_FORGOTPASSWORDERROR = "setForgotPasswordError";
export const SET_FORGOTPASSWORD = "setForgotPassword";
export const SET_RESETPASSWORDERROR = "setRESETPasswordError";
export const SET_RESETPASSWORD = "setRESETPassword";
export const SET_GREETING = "setGreeting";
export const SET_EMAIL_ADDRESS = "setEmailAddress";
export const SET_SEND_ASSETMAIL = "setSendAssetMail";
export const SET_SEND_ASSETMAILERROR = "setSendAssetMailError";
export const DELETE_EDIT_ANSWER = "deleteEditAnswer";
export const ADD_POSSIBLE_RECOMMENDATION = "addPossibleRecommendation";
export const SET_POSSIBLE_RECOMMENDATION_LIST = "setPossibleRecommendationList";
export const ADD_RECOMMENDATION_DESCRIPTION_FOR_ASSETS = "addRecommendationDescriptionForAssets";
export const SET_RECOMMENDATION_DESCRIPTION_FOR_ASSETS = "setRecommendationDescriptionForAssets";
export const DELETE_RECOMMENDED_DESCRIPTION_FOR_ASSETS = "deleteRecommendedDescriptionForAssets";
export const ADD_QUESTION_ANSWER = "addQuestionAnswer";
export const DELETE_RECOMMENDATION_QUESTION_ANSWER = "deleteRecommendationQuestionAnswer";
export const ADD_RECOMMENDATION_EDIT_QUESTION_ANSWER = "addRecommendationEditQuestionAnswer";
export const SET_EDIT_RECOMMENDATION_DESCRIPTION_FOR_ASSETS = "setEditRecommendationDescriptionForAssets";
export const DELETE_RECOMMENDATION_EDIT_QUESTION_ANSWER = "deleteRecommendationEditQuestionAnswer";
export const ADD_RECOMMENDATION_EDIT_DESCRIPTION = "addRecommendationEditDescription";
export const DELETE_RECOMMENDATION_EDIT_DESCRIPTION="deleteRecommendationEditDescription";
export const FETCH_CONSULTANT_DATA = "fetchConsultantData";
export const SET_CONSULTANT_TEMPLATE = "setConsultantTemplate";
export const SET_CONSULTANT_EDIT_THREAT = "setConsultantEditThreat";
export const SET_CONSULTANT_THREAT = "setConsultantThreat";
export const SET_CONSULTANT_THREAT_DROPDOWN = "setConsultantThreatDropdown";
export const SET_CONSULTANT_EDIT_QUESTION = "setConsultantEditQuestion";
export const SET_CONSULTANT_SETTING = "SET_CONSULTANT_SETTING";
export const SET_SALES_AUTH = "SET_SALES_AUTH";
export const GOTO_THREAT_EDIT = "gotoThreatEdit";
export const RESET_AUTH_MESSAGES = "resetAuthMessages";
export const DELETED_CUSTOMER_PROFILE_IMAGE = "deletedCustomerProfileImage";
export const DELETED_CUSTOMER_COMPANY_IMAGE = "deletedCustomerCompanyImage";
export const SET_PROFILE_IMAGE = "setProfileImage";
export const SET_COMPANY_IMAGE = "setCompanyImage";
export const SET_RIGHT_HEADER_STRING = "setRightHeaderString";

export const PROCESSING = "processing";
export const PROCESSED = "processed";
export const NOTIFY_ERROR = 'notifyError';
export const NOTIFY_SUCCESS = 'notifySuccess';