import sweetAlert from "../partials/popup.js";
export default function popupService() {
  const onSuccessMessage = (response) => {
    displaySuccessAlert(response.data.message);
  };

  const onBackendError = (error) => {
    const response = error.response;
    if (response && response.status >= 400) {
      if (response.data.errors.password) {
        displayErrorAlert(response.data.errors.password);
      }
      if (response.data.errors.email) {
        displayErrorAlert(response.data.errors.email);
      }
      if (response.data.errors.asset) {
        displayErrorAlert(response.data.errors.asset);
      }
      return false;
    }
  };
  const onError = (error) => {
    const response = error.response;
    if (response && response.status >= 400) {
      displayErrorAlert(response.data.message);
      return false;
    }
  };

  const displayErrorAlert = (err) => {
    sweetAlert.alertPopup({
      title: err,
      type: "error",
    });
  };

  const displaySuccessAlert = (msg) => {
    sweetAlert.alertPopup({
      title: msg,
      type: "success",
    });
  };

  return {
    onError,
    onBackendError,
    onSuccessMessage,
    displayErrorAlert,
    displaySuccessAlert
  };
}
