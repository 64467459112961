<template>
  <div class="grid grid-cols-2 login md:grid-cols-1 sm:grid-cols-1">
    <div class="left_content">
      <div class="flex sm:justify-center">
        <Logo :isSecurious="securious" classes="h-login-logo sm:my-8 md:mt-16 md:ml-6 sm:ml-0 med:m-10 lg:m-14 xl:m-16"
              dark="true"/>
      </div>
      <div
        class="md:hidden sm:hidden my-0 mx-auto pt-5 px-1 pb-1 flex flex-col mt-6 max-w-xl md:w-full sm:px-4"
      >
        <img
          class="justify-center"
          src="../assets/views/reset-page.svg"
          alt="Securious login page image"
        />
      </div>

      <div
        class="footer-link link-text text-base flex justify-center space-x-4 md:right-1/4 sm:right-1/4"
      >
        <div class="cursor-pointer">
          <a v-if="securious" href="https://securious.de/impressum" target="_">{{ $t('Imprint') }}</a>
          <a v-else href="https://cyberxperts.de/impressum.html" target="_">{{ $t('Imprint') }}</a>
        </div>
        <div class="cursor-pointer">
          <a v-if="securious" href="https://securious.de/datenschutz/" target="_">{{ $t('Data privacy') }}</a>
          <a v-else href="https://cyberxperts.de/datenschutz.html" target="_">{{ $t('Data privacy') }}</a>
        </div>
      </div>
    </div>
    <div
      class="bg-login-background bg-no-repeat bg-center items-start p-0 flex justify-center h-screen"
    >
      <div class="right_content flex">
        <div class="justify-center m-auto w-4/5">
          <div class="bg-white">
            <div class="flex flex-col p-12 gap-6">
              <div class="text-xl font-semibold">{{ $t('Reset Password') }}</div>
              <div class="text-grey-grey text-med">
                {{ $t('Enter your email address to reset your password') }}
              </div>
              <div class="m-4" v-if="backendSuccess.message">
                <div class="bg-green">
                  <p class="text-green-light text-base px-4 py-2">
                    {{ $t('We have sent you an email with instructions on how to reset your password') }}
                  </p>
                </div>
              </div>
              <div class="p-6">
                <div class="relative">
                  <input
                    ref="floating_input"
                    type="email"
                    id="email"
                    name="email"
                    v-model="email"
                    autocomplete="off"
                    required="required"
                    class="w-full py-4 border-grey-grey border-b focus:outline-none appearance-none"
                  />
                  <label
                    class="placeholder-text"
                    for="email"
                    id="placeholder-fname"
                  >
                    <div class="label-text font-normal">
                      <p v-if="backendError.email" class="text-red-red">
                        Ihre@email.com
                      </p>
                      <p v-else class="text-grey-grey">Ihre@email.com</p>
                    </div>
                  </label>
                </div>
                <div>
                  <p
                    class="text-sm text-red-red"
                    v-if="backendError.email?.length > 0"
                  >
                    {{ backendError?.email[0] }}
                  </p>
                </div>
              </div>
              <div>
                <div class="grid grid-cols-3">
                  <button
                    @click="submitEmail"
                    class="reset-btn col-start-2 col-end-3 rounded-lg text-white text-md border-2 border-grey-grey border-opacity-40"
                  >
                    {{ $t('Reset') }}
                  </button>
                </div>
              </div>
              <div class="text-center">
                <p class="text-base text-grey-grey">
                  {{ $t('Back to') }}
                  <router-link class="cursor-pointer text-blue-blue" to="/login"
                    >{{ $t('Login') }}</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { FORGOT_PASSWORD } from "@/store/modules/actions.type";
import { RESET_AUTH_MESSAGES } from "@/store/modules/mutations.type";
import {defineComponent, ref, computed, onBeforeMount, onBeforeUnmount} from "vue";
import Logo from "@/components/Logo.vue";

export default defineComponent({
  components: {
    Logo,
  },
  setup() {
    const securious = ref(true);
    const email = ref("");

    function submitEmail() {
      let data = {
        email: email.value,
      };
      store.dispatch(FORGOT_PASSWORD, data);
    }
    onBeforeMount(() => {
      const url = window.location.href;
      securious.value = url.includes("78.46.206.99") || url.includes(".securious.de");
    });
    onBeforeUnmount(() => {
      store.commit(RESET_AUTH_MESSAGES);
    });
    const backendError = computed(() => store.state.auth.state.error);
    const backendSuccess = computed(() => store.state.auth.state.success);
    return {
      submitEmail,
      email,
      backendError,
      backendSuccess,
      securious
    };
  },
});
</script>

<style scoped>
.right_content {
  position: relative;
  width: 100%;
  height: 100%;
}
button {
    background: linear-gradient(155deg, hsl( var(--twc-primary)) 0%,  hsl( var(--twc-blue-sky)) 100%) !important;
}

button:hover {
  background: linear-gradient(255deg, hsl( var(--twc-primary)) 0%,  hsl( var(--twc-blue-sky)) 100%) !important;
}

.footer-text {
  font-weight: bold;
  color: #707070;
}
.footer-link {
  position: absolute;
  bottom: 2%;
  left: 20%;
}

.link-text {
  color: #2592d1;
}

.reset-btn {
  display: inline-block;
  padding-top: 0.8rem;
  padding-right: 1rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
}

input:focus + .placeholder-text .label-text {
  background-color: white;
  font-size: 0.9rem;
  font-weight: 600;
  transform: translate(0, -100%);
}

input:focus {
  border-bottom-width: 2px;
}

.placeholder-text {
  position: absolute;
  top: 0;
  bottom: 0.5;
  left: 0;
  right: 0;
  background-color: transparent;
  pointer-events: none;
  display: flex;
  align-items: center;
}
.label-text {
  transform: translate(0);
  transition: transform 0.2s ease-out, font-size 0.2s ease-out,
    background-color 0.2s ease-out, color 0.2s ease-out;
}
</style>
