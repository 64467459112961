import {IRecommendation} from "@/Interfaces/customer/IRecommendation";
import {Translation} from "@/models/Translation";
import {parseArgs} from "@/functions";

export class Recommendation implements IRecommendation {
    friendlyTranslations: Array<Translation>;
    id: bigint;
    points: number;
    status: string;
    threat_id: bigint;

    constructor(initializer: any) {
        initializer = parseArgs({
                friendlyTranslations: [],
                id: 0,
                points: 0,
                status: {status: 'not_started'},
                threat_id: 0,
            },
            initializer
        );

        this.friendlyTranslations = initializer.friendlyTranslations;
        this.id = initializer.id;
        this.points = initializer.points;
        this.status = initializer.status.status;
        this.threat_id = initializer.threat_id;
    }
}