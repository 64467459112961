import axios from "axios";
import { GET_LOGS, SET_LOGS } from "./actions.type";
import API_URL from "../../common/config";

export const logs_module = {
  state: {
    log: [],
    pagination: [],
  },
  actions: {
    async [GET_LOGS](context, query) {
      await axios
        .get(API_URL + "/customer/activityLog", {
          params: {
            page: query.page,
            size: query.per_page,
          },
        })
        .then((response) => {
          context.commit(SET_LOGS, response.data.data);
        })
        .catch((err)=>{
          console.log(err);
          
        })
    },
  },
  mutations: {
    [SET_LOGS](state, response) {
      state.log = response.activityLog.items;
      state.pagination = {
        ...state.pagination,
        total: response.total,
      };
    },
  },
};
