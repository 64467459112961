
import {ErrorMessage, Field, Form, useField} from "vee-validate";
import {GET_REFERRAL_DETAILS, REGISTER, REGISTER_CONSULTANT} from "@/store/modules/actions.type";
import {defineComponent} from "vue";
import store from "@/store";
// import PasswordMeter from "vue-simple-password-meter"; //meter was not working before refactoring as well.
import * as Yup from "yup";
import Logo from "@/components/Logo.vue";
import domainMixin from "../mixins/domain.js"
import {NOTIFY_ERROR, SET_MENU, SET_REGISTERERROR} from "@/store/modules/mutations.type";
import router from "@/router";

export default defineComponent({
  mixins: [domainMixin],
  props : {
    type : {
      type : String,
      required : false,
      default : 'company'
    }
  },
  components: {
    Logo,
    // PasswordMeter, //meter was not working before refactoring as well.
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      showPassword: false,
      processing : false,
      enableEmail : true,
      referrer: this.$route.query.ref ?? null, //reference if referred from sales team or consultant invite
      // role: store.state.auth.state.role, //we're not passing role from frontend now
      consultant: false, //this is false as we are not showing branding etc for now  //store.state.auth.state.consultant, //no consultant for now
      email : '', //useField("email"),
      password : '', //useField("password"),
    }
  },
  beforeMount() {
    if (this.referrer) {
      store.dispatch(GET_REFERRAL_DETAILS, {ref: this.referrer}).then((response) => {
        this.email = response.data.email;
        this.enableEmail = false;
      }).catch((error)=>{
        this.enableEmail = true;
        // store.commit(NOTIFY_ERROR, error.message)
        // this.referrer = null; //nullify
      });
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    handleSubmit(credentials) {
      this.processing = true;

      let action = REGISTER;
      if(this.type == 'consultant'){
        action = REGISTER_CONSULTANT;
      }
      store.dispatch(action, {
        email :credentials.email,
        password :credentials.password,
        ref : this.referrer
        // role: this.role, //for now we're not dealing with frontend role, referrer or consultant.
        // referrer: this.referer,
        // consultant: this.consultant
      }).then((response) => {
        router.push({name: "verify"});
        store.commit(SET_MENU, response.data.resource.menus);
        localStorage.setItem(
            "USER",
            JSON.stringify(response.data.resource)
        );
        localStorage.setItem("MENU", JSON.stringify(response.data.resource.menus));
        localStorage.setItem(
            "ID_TOKEN_KEY",
            response.data.resource.token
        );
        localStorage.setItem("INTRODUCTION", "0");
        localStorage.setItem(
            "LANGUAGE",
            response.data.resource.selected_language
        );
      }).catch((error) => {
        store.commit(SET_REGISTERERROR, error);
        this.processing = false;
      });
    }
  },
  setup() {
    const emailField = useField('email');
    const passwordField = useField('password');
    const schema = Yup.object().shape({
      email: Yup.string()
          .email("E-Mail muss eine gültige E-Mail sein")
          .required("E-Mailadresse wird benötigt")
          .label("E-mail")
          .matches(
              /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              "E-Mail muss eine gültige E-Mail sein"
          ),
      password: Yup.string()
          .min(5)
          .required("Passwort wird benötigt")
          .matches(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              "Muss 8 Zeichen enthalten, ein Großbuchstabe, eine Zahl und ein Sonderzeichen"
          )
          .label("Password"),
    });
    return {
      emailField,
      passwordField,
      schema,
    };
  },
});
