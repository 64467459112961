import { createStore } from "vuex";
import { auth_module } from "./modules/auth.module";
import { industry_module } from "./modules/industry.module";
import { news_module } from "./modules/news.module";
import { question_module } from "./modules/question.module";
import { recommendation_module } from "./modules/recommendation.module";
import { settings_module } from "./modules/settings.module";
import { threat_module } from "./modules/threat.module";
import { solutionPartner_module } from "./modules/solutionPartner.module";
import { introduction_module } from "./modules/introduction.module";
import { customer_threat_module } from "./modules/customerThreat.module";
import { logs_module } from "./modules/logs.module";
import { company_asset_module } from "./modules/companyAsset.module";
import { certificate_module } from "./modules/certificate.module";
import { consultant_module } from "./modules/consultant.module";
import { right_header_module } from "./modules/rightHeader.module";
import {PROCESSING, PROCESSED, NOTIFY_ERROR, NOTIFY_SUCCESS} from "./modules/mutations.type"
import popupService from "../service/popup.service";
const { displayErrorAlert,displaySuccessAlert } = popupService();


export default createStore({
  state: {
    processing : false,
    auth: auth_module,
    industry: industry_module,
    news: news_module,
    settings: settings_module,
    threat: threat_module,
    question: question_module,
    recommendation: recommendation_module,
    solutionPartner: solutionPartner_module,
    introduction: introduction_module,
    log: logs_module,
    customerThreat: customer_threat_module,
    companyAsset: company_asset_module,
    certificate: certificate_module,
    consultant: consultant_module,
  },
  mutations: {
    [PROCESSING]: (state) => {
      state.processing = true;
    },
    [PROCESSED]: (state) => {
      state.processing = false;
    },
    [NOTIFY_ERROR](state, error) {
      displayErrorAlert(error);
    },
    [NOTIFY_SUCCESS](state, message) {
      displaySuccessAlert(message);
    },
  },
  actions: {},
  modules: {
    auth_module,
    industry_module,
    news_module,
    settings_module,
    solutionPartner_module,
    threat_module,
    question_module,
    recommendation_module,
    introduction_module,
    logs_module,
    customer_threat_module,
    company_asset_module,
    certificate_module,
    consultant_module,
    right_header_module
  },
});
