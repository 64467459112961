let mixin = {
    data: function () {
        return {
            securious : true
        }
    },
    beforeMount() {
        const url = window.location.href;
        this.securious = url.includes("78.46.206.99") || url.includes(".securious.de") ;
    }
}
export default mixin;