export const LOGIN = "login";
export const VERIFY = "verify";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const REGISTER_CONSULTANT = "registerConsultant";
export const RESEND_VERIFICATION = 'resend_verification';
export const CHECK_AUTH = "checkAuth";
export const GET_INDUSTRIES = "industries";
export const NEW_INDUSTRY = "newIndustry";
export const DELETE_INDUSTRY = "deleteIndustry";
export const STORE_INDUSTRY = "storeIndustry";
export const STORE_NEWS = "storeNews";
export const GET_NEWS = "getNews";
export const EDIT_NEWS = "editNews";
export const UPDATE_NEWS = "updateNews";
export const DELETE_NEWS = "deleteNews";
export const SHOW_NEWS = "showNews";
export const GET_GOOGLE_CODE = "getGoogleCode";
export const ENABLE_EMAIL_2FA = "enableEmail2FA";
export const DISABLE_2FA = 'disableGoogle2FA'
export const VERIFY_GOOGLE_CODE = "verifyGoogleCode";
export const VERIFY_EMAIL_CODE = "verifyEmailCode";
export const GET_SOLUTION_PARTNER = "getSolutionPartner";
export const GET_SOLUTION_PARTNER_PRODUCT = "getSolutionPartnerProduct";
export const UPDATE_THREAT = "updateThreat";
export const STORE_THREAT = "storeThreat";
export const GET_THREATS = "getThreats";
export const EDIT_THREAT = "editThreat";
export const DELETE_THREAT = "deleteThreat";
export const DRAFT_THREAT = "draftThreat";
export const DUPLICATE_THREAT = "duplicateThreat";
export const GET_THREAT_DROPDOWN = "getThreatDropdown";
export const REMOVE_THREAT_IMAGE = "removeThreatImage";
export const EXPORT_THREAT = "exportThreat";
export const STORE_QUESTION = "storeQuestion";
export const UPDATE_QUESTION = "updateQuestion";
export const DELETE_QUESTION = "deleteQuestion";
export const EDIT_QUESTION = "editQuestion";
export const DELETE_ANSWER_IAMGE = "deleteAnswerImage";
export const GET_QUESTION_CONDITIONS = "getQuestionConditions";
export const DELETE_QUESTION_CONDITIONS = "deleteQuestionConditions";
export const EDIT_SOLUTION_PARTNER = "editSolutionPartner";
export const TOGGLE_STATUS = "toggleStatus";
export const DELETE_NEWS_IMAGE = "deleteNewsImage";
export const TOGGLE_NEWS_STATUS = "toggleNewsStatus";
export const TOGGLE_ASSET_STATUS = "toggleAssetStatus";
export const DELETE_ASSET_IMAGE = "deleteAssetImage";
export const DELETE_PRODUCT_IMAGE = "deleteProductImage";
export const DELETE_ASSET_PRODUCT = "deleteAssetProduct";
export const GET_ASSET_DROPDOWN = "getAssetDropdown";
export const DELETE_ASSET_ALERT = "deleteAssetAlert";
export const DELETE_ASSET = "deleteAsset";
export const STORE_ASSET = "storeAsset";
export const UPDATE_ASSET = "updateAsset";
export const STORE_RECOMMENDATION = "storeRecommendation";
export const UPDATE_RECOMMENDATION = "updateRecommendation";
export const DELETE_RECOMMENDATION = "deleteRecommendation";
export const GET_EDIT_RECOMMENDATION = "getEditRecommendation";
export const GET_INTRO_DROPDOWN = "getIntroDropdown";
export const GET_INTRO_INVITE_DETAILS = "getIntroInviteDetails";
export const STORE_INTRODUCTION = "storeIntroduction";
export const GET_CUSTOMER_THREAT = "getCustomerThreat";
export const GET_CUSTOMER_SHOW_THREAT = "getCustomerShowThreat";
export const START_ANALYSIS = "startAnalysis";
export const STORE_CUSTOMER_ANSWER = "storeCustomerAnswer";
export const GET_RECOMMENDATION_DROPDOWN = "getRecommendationDropdown";
export const GET_CUSTOMER_RECOMMENDATION = "getCustomerRecommendation";
export const RESTART_THREAT_ANALYSIS = "restartThreatAnalysis";
export const GET_LOGS = "getLogs";
export const SET_LOGS = "setLogs";
export const GET_COMPANY_ASSET = "getCompanyAsset";
export const SAVE_COMPANY_ASSET = "saveCompanyAsset";
export const DELETE_COMPANY_ASSET = "deleteCompanyAsset";
export const GET_CERTIFICATE_DATA = "getCertificateData";
export const GET_CUSTOMER_RECOMMENDATION_LIST = "getCustomerRecommendationList";
export const GET_RECOMMENDATION_COUNT = "getRecommendationCount";
export const CHANGE_RECOMMENDATION_STATUS = "changeRecommendationStatus";
export const GET_REFERRAL_DETAILS = "getReferralDetails";
export const GET_DASHBOARD_ITEM = "getDashboardItem";
export const GET_CUSTOMER_SETTING = "getCustomerSetting";
export const UPDATE_COMPANY_SETTING = "updateCompanySetting";
export const GET_BILLING_PORTAL = "getBillingPortal";
export const GET_BILLING_DETAILS = "getBillingDetails";
export const INVITE_USER = "inviteUser";
export const INVITE_CUSTOMER = "inviteCustomer";
export const GET_TEAM_MEMBERS = "getTeamMembers";
export const FIND_CONSULTANT = "findConsultant";
export const FETCH_CONSULTANT = "fetchConsultant";
export const IMPORT_FILE = "importFile";
export const RESET_PASSWORD = "resetPassword";
export const FORGOT_PASSWORD = "forgotPassword";
export const GET_GREETING = "getGreeting";
export const GET_EMAILADDRESS = "getEmailAddress";
export const CHANGE_PASSWORD = "changePassword";
export const SEND_ASSET_MAIL = "sendAssetMail";
export const ACTIVATE_THREAT = "activateThreat";
export const DELETE_QUESTION_ANSWER = "deleteQuestionAnswer";
export const GET_CONSULTANT_TEMPLATE = "getConsultantTemplate";
export const GET_CONSULTANT_EDIT_THREAT = "getConsultantEditThreat";
export const FETCH_CONSULTANT_THREAT = "fetchConsultantThreat";
export const FETCH_CONSULTANT_THREAT_DROPDOWN = "fetchConsultantThreatDropdown";
export const STORE_CONSULTANT_USE_TEMPLATE = "storeConsultantUseTemplate";
export const STORE_CONSULTANT_THREAT = "storeConsultantThreat";
export const STORE_CONSULTANT_QUESTION = "storeConsultantQuestion";
export const EDIT_CONSULTANT_QUESTION = "editConsultantQuestion";
export const GET_CONSULTANT_QUESTION_CONDITION = "getConsultantQuestionCondition";
export const UPDATE_CONSULTANT_QUESTION = "updateConsultantQuestion";
export const GET_CONSULTANT_RECOMMENDATION_DROPDOWN = "GET_CONSULTANT_RECOMMENDATION_DROPDOWN";
export const GET_CONSULTANT_SOLUTION_PARTNER_PRODUCT = "GET_SOLUTION_PARTNER_PRODUCT";
export const STORE_CONSULTANT_RECOMMENDATION = "STORE_CONSULTANT_RECOMMENDATION";
export const GET_CONSULTANT_EDIT_RECOMMENDATION = "GET_CONSULTANT_EDIT_RECOMMENDATION";
export const UPDATE_CONSULTANT_RECOMMENDATION = "UPDATE_CONSULTANT_RECOMMENDATION";
export const CHANGE_CONSULTANT_SETTING = "CHANGE_CONSULTANT_SETTING";
export const SHOW_CONSULTANT_EDIT_THREAT = "SHOW_CONSULTANT_EDIT_THREAT";
export const CONSULTANT_ENABLE_DISABLE_THREAT_EDIT = "consultantEnableDisableThreatEdit";
export const CONSULTANT_STORE_ADMIN_OPTIONS = "consultantStoreAdminOptions";
export const SALES_LOGIN = "SALES_LOGIN";
export const SALES_INVITE = "SALES_INVITE";
export const GET_SALES_INVITE_EMAIL = "GET_SALES_INVITE_EMAIL";
export const UPDATE_CONSULTANT_THREAT = "UPDATE_CONSULTANT_THREAT";
export const CONSULTANT_DRAFT_THREAT = "CONSULTANT_DRAFT_THREAT";
export const IMPERSONATE = "IMPERSONATE";
export const STOP_IMPERSONATE = "STOP_IMPERSONATE";
export const GET_MENU = "GET_MENU";
export const DELETE_THREAT_FROM_UPDATE = "DELETE_THREAT_FROM_UPDATE";
export const UPDATE_RECOMMENDATION_STATUS = "UPDATE_RECOMMENDATION_STATUS";
export const DELETE_CUSTOMER_PROFILE_IMAGE = "deleteCustomerProfileImage";
export const DELETE_CUSTOMER_COMPANY_IMAGE = "deleteCustomerCompanyImage";

//Consultant Actions - All the actions related to consultant must be prefixed by "Consultant"
export const CONSULTANT_GET_ADMIN_SETTINGS = "getConsultantAdminSettings";