import {IThreat} from '@/Interfaces/customer/IThreat'
import {Translation} from "@/models/Translation";
import {Recommendation} from "@/models/customer/Recommendation";
import {parseArgs} from "@/functions";
import {AttemptedQuestionAnswer} from "@/models/customer/AttemptedQuestionAnswer";

export class Threat implements IThreat {
    achieved_points: number;
    estimated_time_in_minutes: number;
    friendlyTranslations: Array<Translation>;
    id: bigint;
    image: string;
    is_completed: boolean;
    last_answered_question: bigint;
    points: number;
    progress: number;
    recommendations: Array<Recommendation>;
    video_link: string;
    attemptedQuestionAnswers: Array<AttemptedQuestionAnswer>;
    constructor(initializer: any = null) {
        //convert to object from null
        initializer = parseArgs({
                achieved_points: 0,
                estimated_time_in_minutes: 0,
                friendlyTranslations: [],
                id: 0,
                image: '',
                is_completed: false,
                last_answered_question: 0,
                points: 0,
                progress: 0,
                recommendations: [],
                video_link: '',
                attemptedQuestionAnswers: []
            },
            initializer);
        this.achieved_points = initializer.achieved_points;
        this.estimated_time_in_minutes = initializer.estimated_time_in_minutes;
        this.friendlyTranslations = initializer.friendlyTranslations;
        this.id = initializer.id;
        this.image = initializer.image;
        this.is_completed = initializer.is_completed;
        this.last_answered_question = initializer.last_answered_question;
        this.points = initializer.points;
        this.progress = initializer.progress;
        this.recommendations = initializer.recommendations.map(recommendation => new Recommendation(recommendation));
        this.video_link = initializer.video_link;
        this.attemptedQuestionAnswers = initializer.attemptedQuestionAnswers.map(attemptedQuestionAnswer => new AttemptedQuestionAnswer(attemptedQuestionAnswer));
    }
}