<template>
  <div v-bind:data-theme="theme" v-bind:class="theme">
    <div class="progress-bar" v-if="$store.state.processing">
      <div class="progress-bar-value"></div>
    </div>
    <router-view />
  </div>
</template>
<style lang="scss">
/*internal fonts due to GDPR*/
/*@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;1,400;1,600&display=swap');*/

.swal2-popup.swal2-toast .swal2-title {
  font-size: 14px !important;
}

.slider-tooltip{
  background : hsl( var(--twc-primary)) !important;
  border :  hsl( var(--twc-primary)) !important;
}

.slider-connect{
  background : hsl( var(--twc-primary)) !important;
}


.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 999;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

</style>

<script>

import {defineComponent, onBeforeMount, ref} from "vue";

export default defineComponent({
  setup() {
    const securious = ref(true);
    const theme = ref('securious');
    onBeforeMount(() => {
      const url = window.location.href;
      securious.value = url.includes("78.46.206.99") || url.includes("securious.de");
      if (!securious.value) {
        /**
         * @type {NodeListOf<Element>}
         * change favicons
         */
        const favicons = document.querySelectorAll("link[rel~='icon']");
        favicons.forEach((favicon) => {
          favicon.href = "/vnr-favicon.png";
        });
        /**
         * change domain specific styling
         */
        theme.value = 'cyberXperts';
      }
    });
    return {
      theme : theme
    }
  }
});
</script>