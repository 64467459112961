import {
  ADD_ANSWER,
  DELETE_ANSWER,
  ADD_EDIT_ANSWER,
  SET_ANSWER_LIST,
  SET_EDIT_QUESTION,
  SET_QUESTION_CONDITION,
  DELETE_EDIT_ANSWER,
  SET_POSSIBLE_RECOMMENDATION_LIST,
  RESET_QUESTION,
  SET_CONSULTANT_EDIT_QUESTION,
  QUESTION_DELETED,
  GOTO_EDIT_QUESTION,
} from "./mutations.type";
import router from "@/router";
import axios from "axios";
import {
  EDIT_QUESTION,
  STORE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  GET_QUESTION_CONDITIONS,
  DELETE_QUESTION_CONDITIONS,
  DELETE_ANSWER_IAMGE,
  DELETE_QUESTION_ANSWER,
  STORE_CONSULTANT_QUESTION,
  EDIT_CONSULTANT_QUESTION,
  GET_CONSULTANT_QUESTION_CONDITION,
  UPDATE_CONSULTANT_QUESTION,
} from "./actions.type";
import API_URL from "../../common/config";
import { notify } from "@kyvg/vue3-notification";
import {useStore} from "vuex";


export const question_module = {
  state: {
    question: {
      threat_id: 0,
      company_size: [0, 0],
      assets: [],
      industries: [],
      question_type: "Radio",
      details_level: "Low",
      show_if_using_assets: false,
      display_if_conditions: false,
      automation_conditions: false,
      order: 0,
      video_link: "",
      show_if_industry: false,
      show_if_company_size: false,
      friendlyTranslations: {
        EN: {
          title: "",
          description: "",
          explanation: "",
        },
        DE: {
          title: "",
          description: "",
          explanation: "",
        },
      },
      answers: [],
      recommendation: [],
      display_conditions: [],
    },
    editQuestion: {
      id : null
    },
    questionCondition: [],
    questionDropdown: [],
    answerDropdown: [],
    language: localStorage.getItem("LANGUAGE"),
  },
  actions: {
    [STORE_QUESTION]({ state, commit }, status) {
      state.question.threat_id = router.currentRoute.value.params.id;
      state.question.status = status;

      return axios
        .post(API_URL + "/admin/analysis_question", state.question)
        .then((response) => {
          notify({
            title: "Frage erfolgreich erstellt",
            type: "success",
          });
          router.push({name: 'editThreatQuestion', params: {id: response.data.data.id}})
          commit(RESET_QUESTION)
          // commit(GOTO_EDIT_QUESTION,response.data.data.id)
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
     
    },
    [STORE_CONSULTANT_QUESTION]({ state }, status) {
      state.question.threat_id = router.currentRoute.value.params.id;
      state.question.status = status;

      return axios
        .post(API_URL + "/consultant/analysis-question", state.question)
        .then(() => {
          notify({
            title: "Frage erfolgreich erstellt",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        });
     
    },
    [UPDATE_QUESTION]({ state }, status) {
      state.editQuestion.status = status;
      axios
        .put(
          API_URL + "/admin/analysis_question/" + state.editQuestion.id,
          state.editQuestion
        )
        .then(() => {
          notify({
            title: "Frage erfolgreich aktualisiert",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        });
    },
    [DELETE_QUESTION]({ state, commit }, { status, id }) {
      state.editQuestion.status = status;
      if (id)
        axios.delete(API_URL + "/admin/analysis_question/" + id)
        .then(() => {

          notify({
            title: "Frage erfolgreich gelöscht",
            type: "success",
          });

        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
      else
        axios
          .delete(API_URL + "/admin/analysis_question/" + state.editQuestion.id)
          .then(() => {
            notify({
              title: "Frage erfolgreich gelöscht",
              type: "success",
            });
            commit(QUESTION_DELETED)

          })
          .catch(() => {
            notify({
              title: "Etwas ist schief gelaufen",
              type: "fail",
            });
          })
    },
    async [EDIT_QUESTION](context, id) {
      await axios
        .get(API_URL + "/admin/analysis_question/" + id + "/edit")
        .then((response) => {
          context.commit(SET_EDIT_QUESTION, response.data.data);
        });
    },
   [EDIT_CONSULTANT_QUESTION](context, id) {
      return axios
        .get(API_URL + "/consultant/edit-question/"+id)
        .then((response) => {
          context.commit(SET_CONSULTANT_EDIT_QUESTION, response.data.data);
        });
    },

    [GET_QUESTION_CONDITIONS](context, threat_id) {
      axios
        .get(API_URL + "/admin/analysis_question/create", {
          params: { threat_id: threat_id },
        })
        .then((response) => {
          context.commit(SET_QUESTION_CONDITION, response.data.data);
        });
    },
    [GET_CONSULTANT_QUESTION_CONDITION](context, threat_id) {
      axios
        .get(API_URL + "/consultant/question-condition/"+threat_id, {
        })
        .then((response) => {
          context.commit(SET_QUESTION_CONDITION, response.data.data);
        })
        .catch((err)=>{
          console.log(err);
          
        })
    },
    [UPDATE_CONSULTANT_QUESTION]({ state }, status) {
      state.editQuestion.status = status;
      axios
        .put(
          API_URL + "/consultant/update-question/" + state.editQuestion.id,
          state.editQuestion
        )
        .then(() => {
          notify({
            title: "Frage erfolgreich aktualisiert",
            type: "success",
          });
        })
        
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [DELETE_QUESTION_CONDITIONS](context, id) {
      axios
        .post(API_URL + "/admin/analysis_question/conditionDelete/" + id)
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [DELETE_QUESTION_ANSWER](context, data) {
      axios
        .post(API_URL + "/admin/analysis_question/deleteAnswer/" + data.id)
        .then((response) => {
          context.commit(DELETE_EDIT_ANSWER, data);
        })
        .then(() => {
          notify({
            title: "Antwort erfolgreich gelöscht",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "fail",
          });
        })
    },
    [DELETE_ANSWER_IAMGE](context, id) {
      axios.post(API_URL + "/admin/analysis_question/deleteImage/" + id);
    },

  },
  mutations: {
    [ADD_ANSWER](state, answer) {
      state.question.answers.push(answer);
    },
    [DELETE_ANSWER](state, index) {
      state.question.answers.splice(index, 1);
    },
    [QUESTION_DELETED](context) {
      router.push({ name: 'threatUpdate', params: { id: context.editQuestion.threat_id }, query: {tab: 'analysis'} })
    },
    [GOTO_EDIT_QUESTION](context, id) {
      router.push({ name: 'editThreatQuestion', params: { id: id } })
    },
    [DELETE_EDIT_ANSWER](state, data) {
      const index = state.editQuestion.answers.indexOf(data);
      state.editQuestion.answers.splice(index, 1);
    },

    [ADD_EDIT_ANSWER](state, answer) {
      state.editQuestion.answers.push(answer);
    },

    [SET_ANSWER_LIST](state, data) {
      if (router.currentRoute.value.params.id)
        state.editQuestion.answers = data;
      else state.question.answers = data;
    },
    [SET_POSSIBLE_RECOMMENDATION_LIST](state, data) {
      if (router.currentRoute.value.params.id)
        state.editQuestion.recommendation = data;
      else state.question.recommendation = data;
    },
    [SET_EDIT_QUESTION](state, data) {
      state.editQuestion = data;
      state.questionDropdown = data.questionCondition.map((question) => {
        return {
          id: question["id"],
          name: question["friendlyTranslations"][state.language + ".title"][
            "value"
          ],
        };
      });
      state.editQuestion.display_conditions.map((cond) => {
        return (cond.answers = cond.answers.map((ans) => {
          return {
            id: ans["id"],
            name: ans["friendlyTranslations"][state.language + ".title"][
              "value"
            ],
          };
        }));
      });
    },
    [SET_CONSULTANT_EDIT_QUESTION](state, data) {
      state.editQuestion = data;
      state.questionDropdown = data.questionCondition.map((question) => {
        return {
          id: question["id"],
          name: question["friendlyTranslations"][state.language + ".title"] ? question["friendlyTranslations"][state.language + ".title"]["value"] : '',
        };
      });
      state.editQuestion.display_conditions.map((cond) => {
        return (cond.answers = cond.answers.map((ans) => {
          return {
            id: ans["id"],
            name: ans["friendlyTranslations"][state.language + ".title"][
              "value"
            ],
          };
        }));
      });
    },
    [SET_QUESTION_CONDITION](state, data) {
      state.questionCondition = data.questionCondition;
      state.questionDropdown = data.questionCondition.map((question) => {
        return {
          id: question["id"],
          name: question["friendlyTranslations"][state.language + ".title"] ? question["friendlyTranslations"][state.language + ".title"]["value"] : '',
        };
      });
      state.question.order = data.questionOrder;
    },
    [RESET_QUESTION](state, data) {
      state.question = {
        threat_id: 0,
        company_size: [0, 0],
        assets: [],
        industries: [],
        question_type: "Radio",
        details_level: "Low",
        show_if_using_assets: false,
        display_if_conditions: false,
        automation_conditions: false,
        order: ++state.question.order,  //Preserve current order state and increment by 1
        video_link: "",
        show_if_industry: false,
        show_if_company_size: false,
        friendlyTranslations: {
          EN: {
            title: "",
            description: "",
            explanation: "",
          },
          DE: {
            title: "",
            description: "",
            explanation: "",
          },
        },
        answers: [],
        recommendation: [],
        display_conditions: [],
      }
    },
  },
};
