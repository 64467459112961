import router from "@/router";
import axios from "axios";
import API_URL from "../../common/config";
import {SET_COMPANY_IMAGE, SET_PROFILE_IMAGE, SET_RIGHT_HEADER_STRING} from '../modules/mutations.type';

export const right_header_module = {
    state: {
        company_image: false,
        profile_image: false,
        name: false,
        company_name: false
    },
    actions: {},
    mutations: {
        [SET_COMPANY_IMAGE](state, data){
            state.company_image = data;
            localStorage.setItem('CompanyImage', data)
        },
        [SET_PROFILE_IMAGE](state, data){
            state.profile_image = data;
            localStorage.setItem('ProfileImage', data)
        },
        [SET_RIGHT_HEADER_STRING](state, data){
            // console.log('called');
            const user = JSON.parse(localStorage.getItem('USER') ?? "user") ?? "";
            user.first_name =data.first_name;
            user.last_name =data.last_name;
            user.name = data.first_name + ' ' + data.last_name;
            user.company_name = data.company_name;
            // console.log('user after', user);
            localStorage.setItem("USER", JSON.stringify(user));

            state.name = data.first_name + ' ' + data.last_name;
            state.company_name = data.company_name;

        }
    }
}