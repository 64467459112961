import {Translation} from "@/models/Translation";
import {parseArgs} from "@/functions";
import {IAttemptedQuestionAnswer} from "@/Interfaces/customer/IAttemptedQuestionAnswer";
import {Answer} from "@/models/Answer";

export class AttemptedQuestionAnswer implements IAttemptedQuestionAnswer {

    constructor(initializer: any) {
        initializer = parseArgs({
                friendlyTranslations: [],
                id: 0,
                customer_answers: [],
            },
            initializer
        );
        this.customer_answers = initializer.customer_answer;
        this.friendlyTranslations = initializer.friendlyTranslations;
        this.id = initializer.id;
    }

    customer_answers: Array<Answer>;
    friendlyTranslations: Array<Translation>;
    id: bigint;
}