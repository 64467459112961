<template>
  <div class="grid grid-cols-2 login md:grid-cols-1 sm:grid-cols-1">
    <div class="left_content">
      <div class="flex sm:justify-center">
        <Logo :isSecurious="securious" classes="h-login-logo sm:my-8 md:mt-16 md:ml-6 sm:ml-0 med:m-10 lg:m-14 xl:m-16"
              dark="true"/>
      </div>
      <div
        class="md:hidden sm:hidden my-0 mx-auto pt-5 px-1 pb-1 flex flex-col mt-6 max-w-xl md:w-full sm:px-4"
      >
        <img
          class="justify-center"
          src="../assets/views/reset-page.svg"
          alt="Securious login page image"
        />
      </div>

      <div
        class="footer-link link-text text-base flex justify-center space-x-4 md:right-1/4 sm:right-1/4"
      >
        <div class="cursor-pointer">
          <a v-if="securious" href="https://securious.de/impressum" target="_">{{ $t('Imprint') }}</a>
          <a v-else href="https://cyberxperts.de/impressum.html" target="_">{{ $t('Imprint') }}</a>
        </div>
        <div class="cursor-pointer">
          <a v-if="securious" href="https://securious.de/datenschutz/" target="_">{{ $t('Data privacy') }}</a>
          <a v-else href="https://cyberxperts.de/datenschutz.html" target="_">{{ $t('Data privacy') }}</a>
        </div>
      </div>
    </div>
    <div
      class="bg-login-background bg-no-repeat bg-center items-start p-0 flex justify-center h-screen"
    >
      <div class="right_content flex">
        <div class="justify-center m-auto w-3/5 medium:w-full">
          <div class="bg-white">
            <div class="flex flex-col py-8 px-10 gap-6">
              <div>
<!--                <div class="text-xxl text-black font-semibold">-->
<!--                  <p v-if="greeting">{{ greeting }},</p>-->
<!--                </div>-->
                <div>
                  <p class="text-grey-grey text-base">
                    {{ $t('We have received a request to change the password for your securious account Please enter your new password here') }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-6">
                <label for="password">
                  <div class="font-normal text-grey-grey">{{ $t('New Password') }}</div>
                </label>
                <div class="relative" v-if="showPassword">
                  <input
                    type="text"
                    id="password"
                    :name="$t('New Password')"
                    autocomplete="off"
                    v-model="password"
                    required="required"
                    class="w-full py-1 border-grey-grey border-b focus:outline-none appearance-none"
                  />
                  <span
                    @click="toggleShow"
                    class="text-grey-grey text-medium fa fa-fw field-icon absolute right-2 top-2 fa-eye"
                  ></span>
                </div>
                <div class="relative" v-else>
                  <input
                    type="password"
                    id="password"
                    :name="$t('New Password')"
                    autocomplete="off"
                    v-model="password"
                    required="required"
                    class="w-full py-1 border-grey-grey border-b focus:outline-none appearance-none"
                  />
                  <span
                    @click="toggleShow"
                    class="text-grey-grey text-medium fa fa-fw field-icon absolute right-2 top-2 fa-eye-slash"
                  ></span>
                  <password-meter :password="password" />
                </div>
                <div>
                  <label for="confirm_password">
                    <div class="font-normal text-grey-grey">
                      {{ $t('Confirm password') }}
                    </div>
                  </label>
                  <div>
                    <input
                      type="password"
                      id="confirm_password"
                      :name="$t('Confirm password')"
                      autocomplete="off"
                      v-model="confirmPassword"
                      required="required"
                      class="w-full py-4 border-grey-grey border-b focus:outline-none appearance-none"
                    />
                  </div>
                </div>
                <div class="text-center">
                  <p class="text-base text-grey-grey">
                    {{ $t('Back to') }}
                    <a class="cursor-pointer text-blue-blue" href="/login"
                      >{{ $t('Login') }}</a
                    >
                  </p>
                </div>
                <div class="text-center">
                  <button
                    class="submit-btn rounded-lg text-white text-md border-2 border-grey-grey border-opacity-40"
                    @click="submitPassword"
                  >
                    {{ $t('Reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { RESET_PASSWORD } from "@/store/modules/actions.type";
import { defineComponent, ref, computed, onMounted,onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import PasswordMeter from "vue-simple-password-meter";
import { GET_GREETING } from "@/store/modules/actions.type";
import Logo from "@/components/Logo.vue";

export default defineComponent({
  components: {
    Logo,
    PasswordMeter,
  },
  setup() {
    const password = ref();
    const securious = ref(true);
    const confirmPassword = ref();
    const route = useRoute();

    // onMounted(() => {
    //   store.dispatch(GET_GREETING, { email: route.query.email });
    // });

    onBeforeMount(() => {
      const url = window.location.href;
      securious.value = url.includes("78.46.206.99") || url.includes(".securious.de");
    });
    function submitPassword() {
      let data = {
        email: route.query.email,
        password: password.value,
        password_confirmation: confirmPassword.value,
        token: route.query.token,
      };
      store.dispatch(RESET_PASSWORD, data);
    }
    const showPassword = ref(false);
    function toggleShow() {
      showPassword.value = !showPassword.value;
    }
    // const greeting = computed(() => store.state.introduction.state.greeting);

    return {
      password,
      confirmPassword,
      submitPassword,
      showPassword,
      toggleShow,
      // greeting,
      securious
    };
  },
});
</script>

<style scoped>
.right_content {
  position: relative;
  width: 100%;
  height: 100%;
}
button {
    background: linear-gradient(155deg, hsl( var(--twc-primary)) 0%,  hsl( var(--twc-blue-sky)) 100%) !important;
}

button:hover {
  background: linear-gradient(255deg, hsl( var(--twc-primary)) 0%,  hsl( var(--twc-blue-sky)) 100%) !important;
}

.footer-text {
  font-weight: bold;
  color: #707070;
}
.footer-link {
  position: absolute;
  bottom: 2%;
  left: 20%;
}

.link-text {
  color: #2592d1;
}

.submit-btn {
  display: inline-block;
  padding-top: 0.8rem;
  padding-right: 1rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
}

input:focus {
  border-bottom-width: 2px;
}
</style>
