import axios from "axios";
import {
  STORE_NEWS,
  GET_NEWS,
  EDIT_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
  DELETE_NEWS_IMAGE,
  TOGGLE_NEWS_STATUS,
  SHOW_NEWS,
} from "./actions.type";
import API_URL from "../../common/config";
import { notify } from "@kyvg/vue3-notification";
import { RESET_NEWS, SET_NEWS } from "./mutations.type";

export const news_module = {
  state: {
    news: {
      friendlyTranslations: {
        EN: {
          title: "",
          excerpt: "",
          description: "",
        },
        DE: {
          title: "",
          excerpt: "",
          description: "",
        },
      },
      news_category_id: "",
      is_active: false,
      image: "",
    },
    getNews: [],
    editNews: [],
    showNews: [],
    pagination: [],
  },
  actions: {
    [STORE_NEWS](context, news) {
      axios.post(API_URL + "/admin/news", news)
      .then((response) => {
        context.commit(RESET_NEWS);
        notify({
          title: "Nachrichten erfolgreich erstellt",
          type: "success",
        });
      })
      .catch((response) => {
        notify({
          title: "Etwas ist schief gelaufen ",
          type: "fail",
        });
      });
    },

    [GET_NEWS] (context,{ page, search='' }){
        axios.get(API_URL+'/admin/news',{ params: { pages: page, search: search }}).then((response) => {
            context.commit(SET_NEWS,response)
        })
        .catch((err)=>{
          console.log(err);
          
        })
    },

    [EDIT_NEWS](context, id) {
      axios.get(API_URL + "/admin/news/" + id + "/edit").then((response) => {
        context.commit(EDIT_NEWS, response.data.data);
      }).catch((err)=>{
        console.log(err);
        context.commit(EDIT_NEWS, []);
        
      })
    },
    [SHOW_NEWS](context, id) {
      axios.get(API_URL + "/customer/news/" + id).then((response) => {
        context.commit(SHOW_NEWS, response.data.data);
      })
      .catch((err)=>{
        console.log(err);
        context.commit(SHOW_NEWS, []);
        
      })
    },
    [UPDATE_NEWS]({ state }) {
      axios
        .put(API_URL + "/admin/news/" + state.editNews.id, state.editNews)
        .then((response) => {
          notify({
            title: "Nachrichten erfolgreich aktualisiert",
            type: "success",
          });
        })
        .catch((response) => {
          notify({
            title: "Etwas ist schief gelaufen",
            type: "error",
          });
        });
    },
    [DELETE_NEWS]({ commit, dispatch }, { id, index }) {
      axios.delete(API_URL + "/admin/news/" + id).then((response) => {
        if (index != null) commit(DELETE_NEWS, index);
        dispatch(GET_NEWS);
        notify({
          title: "Nachrichten erfolgreich gelöscht",
          type: "success",
        });
      }).catch((err)=>{
        notify({
          title: "Etwas ist schief gelaufen",
            type: "error",
        });
      })
    },
    async [DELETE_NEWS_IMAGE]({ state }) {
      await axios.post(
        API_URL + "/admin/news/deleteImage/" + state.editNews.id
      );
    },
    [TOGGLE_NEWS_STATUS](context, { id, is_active }) {
      axios
        .post(API_URL + "/admin/news/toggleStatus", { id, is_active })
        .then(() => {
          notify({
            title: " Nachrichten erfolgreich aktualisiert ",
            type: "success",
            text: is_active ? "Aktiviert" : "Deaktiviert",
          });
        })
        .catch(() => {
          notify({
            title: "Nachrichtenaktualisierung fehlgeschlagen ",
            type: "fail",
          });
        });
    },
  },
  mutations: {
    [SET_NEWS](state, response) {
      state.getNews = response.data.data.items;
      state.pagination = response.data.data.pagination;
    },
    [EDIT_NEWS](state, data) {
      state.editNews = data;
    },
    [SHOW_NEWS](state, data) {
      state.showNews = data;
    },
    [DELETE_NEWS](state, index) {
      state.getNews.splice(index, 1);
    },
    [RESET_NEWS](state) {
      state.news = {
        friendlyTranslations: {
          EN: {
            title: "",
            excerpt: "",
            description: "",
          },
          DE: {
            title: "",
            excerpt: "",
            description: "",
          },
        },
        news_category_id: "",
        is_active: false,
        image: "",
      };
    },
  },
};
