import axios from "axios";
import API_URL from "@/common/config.js";
import {
  CONSULTANT_STORE_ADMIN_OPTIONS,
  CONSULTANT_ENABLE_DISABLE_THREAT_EDIT,
  CHANGE_CONSULTANT_SETTING,
  FETCH_CONSULTANT,
  FETCH_CONSULTANT_THREAT,
  FETCH_CONSULTANT_THREAT_DROPDOWN,
  GET_CONSULTANT_EDIT_THREAT,
  GET_CONSULTANT_TEMPLATE,
  INVITE_CUSTOMER,
  GET_TEAM_MEMBERS,
  SHOW_CONSULTANT_EDIT_THREAT,
  STORE_CONSULTANT_QUESTION,
  STORE_CONSULTANT_THREAT,
  STORE_CONSULTANT_USE_TEMPLATE,
  UPDATE_CONSULTANT_THREAT,
  CONSULTANT_DRAFT_THREAT,
  CONSULTANT_GET_ADMIN_SETTINGS
} from "./actions.type";
import { notify } from "@kyvg/vue3-notification";
import {
    FETCH_CONSULTANT_DATA,
    SET_CONSULTANT_EDIT_THREAT,
    SET_CONSULTANT_TEMPLATE,
    SET_CONSULTANT_THREAT,
    SET_CONSULTANT_THREAT_DROPDOWN,
    SET_COMPANY_IMAGE,
    SET_PROFILE_IMAGE,
    SET_TEAM_MEMBERS, PROCESSING, PROCESSED
} from "./mutations.type";
import router from "@/router";
import Swal from "sweetalert2";

export const consultant_module = {
  state: {
    invite: [],
    inviteLink: "",
    invitedUsers: [],
    loading: true,
    templateThreats: {
      loading: false,
      data: []
    },
    threat: [],
    editThreat: [],
    industries: [],
    assets: [],
    categories: [],
    getIndustries: [],
    getAssets: [],
    getCategories: [],
    plans: [],
    pagination: [],
    settings: {
      id: '',
      consultant_info: false,
      data_leak: false,
      assets: false,
      alerts: false,
      solution_partner: false,
      default_threat: false,
      news_type: false,
      admin_default_threat: false
    },
    createThreat: {
      image: "",
      categories: [],
      estimated_time_in_minutes: "",
      video_link: "",
      is_always_important: false,
      important_if_industry_id: false,
      important_if_company_size: false,
      is_active: false,
      show_if_industry: false,
      show_if_subscription: false,
      show_if_company_size: false,
      show_if_using_asset: false,
      status: "",
      user_id: null,
      friendlyTranslations: {
        EN: {
          title: "",
          description: "",
        },
        DE: {
          title: "",
          description: "",
        },
      },
      important_company_size: [0, 0],
      company_size: [0, 0],
      assets: [],
      industries: [],
      important_industry: [],
      question_dropdown: [],
      subscription_plans: []
    },
  },
  actions: {
    [GET_TEAM_MEMBERS](context) {
      axios.get(API_URL + "/consultant/fetch").then((response) => {
        context.commit(SET_TEAM_MEMBERS, response.data.invitedUsers);
      })
          .catch((err) => {
            console.log(err);

          })
      // axios.get(API_URL + "/customer/teams").then((response) => {
      //   context.commit(SET_TEAM_MEMBERS, response.data.data);
      // });
    },
    [INVITE_CUSTOMER](context, data) {
      return axios
        .post(API_URL + "/consultant/invite", data)
        .then((response) => {
          context.commit(INVITE_CUSTOMER, response.data.data);
        })
        .then((response) => {
          Swal.fire({
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            icon: "success",
            title: "Einladung erfolgreich versendet",
          });
          notify({
            title: "Einladung erfolgreich versendet",
            type: "success",
          });
          return response;
        })
        .catch((err) => {
          console.log(err.response);


          Swal.fire({
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            icon: "error",
            title: err.response.data.errors.email ? "Ein Nutzer mit dieser E-Mail wurde bereits eingeladen." : err.response.data.message,
          });
          notify({
            title: err.response.data.errors.email ? "Ein Nutzer mit dieser E-Mail wurde bereits eingeladen." : err.response.data.message,
            type: "fail",
          });

          throw err;
        });
    },
    [FETCH_CONSULTANT](context) {
      return axios
        .get(API_URL + "/consultant/fetch")
        .then((response) => {
          context.commit(FETCH_CONSULTANT_DATA, response);
          context.commit(SET_PROFILE_IMAGE, response.data.profile.profile.image)
          context.commit(SET_COMPANY_IMAGE, response.data.profile.company.image)
        })
        .catch((err) => {
          console.log(err);
          context.commit(FETCH_CONSULTANT_DATA, []);

        });
    },

    [CONSULTANT_GET_ADMIN_SETTINGS](context) {
      return axios
        .get(API_URL + "/consultant/admin-settings")
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },
    [GET_CONSULTANT_TEMPLATE](context, param) {
      return axios
        .get(API_URL + "/consultant/admin-threats", {
          params: param
        })
        .then((response) => {
          context.commit(SET_CONSULTANT_TEMPLATE, response.data);
        })
        .catch((err) => {
          console.log(err);
          context.commit(SET_CONSULTANT_TEMPLATE, []);

        })
    },
    [GET_CONSULTANT_EDIT_THREAT](context, param) {
      return axios
        .get(API_URL + "/consultant/edit-threats", {
          params: param
        })
        .then((response) => {
          context.commit(SET_CONSULTANT_EDIT_THREAT, response.data);
        })
        .catch((err) => {
          console.log(err);
          context.commit(SET_CONSULTANT_EDIT_THREAT, []);
        })
    },
    [FETCH_CONSULTANT_THREAT](context, id) {
      return axios
        .get(API_URL + "/consultant/see-threat/" + id)
        .then((response) => {
          context.commit(SET_CONSULTANT_THREAT, response.data);
        })
        .catch((err) => {
          console.log(err);
          context.commit(SET_CONSULTANT_THREAT, []);

        })
    },
    [FETCH_CONSULTANT_THREAT_DROPDOWN](context) {
      return axios
        .get(API_URL + "/consultant/threat/getDropdown")
        .then((response) => {
          context.commit(SET_CONSULTANT_THREAT_DROPDOWN, response);
        })
    },
    [STORE_CONSULTANT_USE_TEMPLATE](context, id) {
      context.commit(PROCESSING);
      return axios
        .get(API_URL + "/consultant/use-template/" + id)
        .then(() => {
          context.commit(PROCESSED);
          notify({
            title: "Vorlage erfolgreich kopiert",
            type: "success",
          });
        })
        .catch(() => {
          context.commit(PROCESSED);
          notify({
            title: " Etwas ist schief gegangen ",
            type: "fail",
          });
        })
    },
    [STORE_CONSULTANT_THREAT]({state}, status) {
      const user_id = localStorage.getItem("USER");
      state.createThreat.status = status;

      if (user_id)
        state.createThreat.user_id = JSON.parse(user_id)['id']

      return axios.post(API_URL + "/consultant/save-threat", state.createThreat).then((response) => {
        notify({
          title: "Bedrohung erfolgreich erstellt",
          type: "success",
        });

        router.push({
          name: "threatUpdate",
          params: {id: response.data.data},
        });
      })
          .catch((err) => {
            console.log(err);
            notify({
              title: " Etwas ist schief gegangen ",
              type: "fail",
            });
          })
    },
    [CHANGE_CONSULTANT_SETTING]({ state }) {
      return axios.post(API_URL + '/consultant/update-setting', state.settings);
    },
    [CONSULTANT_STORE_ADMIN_OPTIONS](context, data) {
      return axios.post(API_URL + '/admin/enable_consultant_options', data).then((response) => {
        notify({
          title: "Einstellungen erfolgreich gespeichert",
          type: "success"
        });
      })
        .catch((err) => {
          console.log(err);

          notify({
            title: " Etwas ist schief gegangen ",
            type: "fail",
          });
        })
    },
    [UPDATE_CONSULTANT_THREAT]({ state }, status) {
      state.threat.status = status;
      axios
        .put(API_URL + "/consultant/update-threat/" + state.threat.id, state.threat)
        .then((response) => {
          notify({
            title: "Bedrohung erfolgreich aktualisiert",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);

          notify({
            title: " Etwas ist schief gegangen ",
            type: "fail",
          });
        });
    },
    [CONSULTANT_DRAFT_THREAT]({ state }) {
      let data = {};
      data = state.threat;

      return axios
        .post(API_URL + "/consultant/draftThreat", data)
        .then(() => {
          notify({
            title: " Bedrohung erfolgreich erstellt ",
            type: "success",
          });
        })
        .catch(() => {
          notify({
            title: " Etwas ist schief gegangen ",
            type: "fail",
          });
        });
    },
  },
  mutations: {
    [SET_TEAM_MEMBERS](state, data) {
      state.invitedUsers = data;
    },
    [INVITE_CUSTOMER](state, data) {
      state.invite = data;
    },
    [FETCH_CONSULTANT_DATA](state, { data }) {
      state.loading = false;
      state.inviteLink = data.link;
      state.invitedUsers = data.invitedUsers;
      state.settings.id = data?.settings?.id;
      state.settings.consultant_info = data.settings?.consultant_info ? true : false;
      state.settings.data_leak = data.settings?.data_leak ? true : false;
      state.settings.assets = data.settings?.assets ? true : false;
      state.settings.alerts = data.settings?.alerts ? true : false;
      state.settings.solution_partner = data.settings?.solution_partner ? true : false;
      state.settings.default_threat = data.settings?.default_threat ? true : false;
      state.settings.admin_default_threat = data.admin_threat_enable ? true : false;
    },
    [SET_CONSULTANT_TEMPLATE](state, data) {
      state.templateThreats.loading = false;
      state.templateThreats.data = data.data.items;
      state.pagination = data.data.pagination;
    },
    [SET_CONSULTANT_EDIT_THREAT](state, data) {
      state.editThreat = data.data.items;
      state.pagination = data.data.pagination;
    },
    [SET_CONSULTANT_THREAT](state, data) {
      state.threat = data.data;
    },
    [SET_CONSULTANT_THREAT_DROPDOWN](state, response) {
      const language = localStorage.getItem("LANGUAGE");
      state.industries = response.data.data[0].items;
      state.assets = response.data.data[1].items;
      state.categories = response.data.data[2].items;
      state.getIndustries = state.industries.map((industry) => {
        return {
          id: industry["id"],
          name: industry["friendlyTranslations"][language + ".title"]?.value,
        };
      });
      state.getAssets = state.assets.map((asset) => {
        return {
          id: asset["id"],
          name: asset["friendlyTranslations"][language + ".title"]?.value,
        };
      });
      state.getCategories = state.categories.map((category) => {
        return {
          id: category["id"],
          name: category["friendlyTranslations"][language + ".title"]?.value,
        };
      });
      state.plans = response.data.data[3];
    }
  },
};
